import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ManageAdminValidationSchema } from '@/views/admin-users-list/manage-admin-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

export type Options = Omit<
  UseMutationOptions<string, DefaultError, ManageAdminValidationSchema>,
  'mutationKey' | 'mutationFn'
>;

export const useCreateAdminMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: ManageAdminValidationSchema): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`admins`, value);
    return data;
  };

  return useMutation({
    mutationKey: ['admins'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['admins'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    ...options,
  });
};
