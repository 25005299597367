'use client';
import { useTranslations } from 'next-intl';
import { AllClientsTable } from './table/all-clients-table';

export function AllClientsView() {
  const t = useTranslations();

  return (
    <main className='pl-4 pr-6'>
      <h1 className='mt-2 py-4 text-2xl font-bold'>{t('Pages.AllClients.title')}</h1>
      <AllClientsTable />
    </main>
  );
}
