'use client';

import { Button } from '@/components/ui';
import { X } from 'lucide-react';
import { Chips } from '@/components/common';

interface Props {
  title: string;
  type: string;
  onClose: () => void;
}

export function ScheduleAccount({ title, type, onClose }: Props) {
  return (
    <div className="relative flex min-h-20 w-full flex-col rounded-sm border border-blue-200 p-2">
      <p className="text-sm text-blue-950">{title}</p>
      <div className="mt-2 flex flex-row items-center">
        <Chips title={type} />
      </div>
      <Button className="absolute right-1 top-1 h-6 w-6" variant="ghost" size="iconSm" onClick={onClose}>
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
}
