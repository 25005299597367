'use client';
import { useTranslations } from 'next-intl';
import { LogInForm } from './login-form';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { toast, ToastTypeEnums } from '@/components/common';

export function LoginView() {
  const t = useTranslations();
  const params = useSearchParams();
  const isNoAccess = params.get('error') === 'noAccess';
  const isNoAccessFinishAuth = params.get('error') === 'noAccessFinishAuth';

  useEffect(() => {
    if (isNoAccess || isNoAccessFinishAuth) {
      setTimeout(() => {
        toast({
          title: t('Toasts.failed'),
          description: isNoAccessFinishAuth ? t('Toasts.loginGoogleErrorFinishAuth') : t('Toasts.loginGoogleError'),
          typeIcon: ToastTypeEnums.WARNING,
        });
      }, 200);
    }
  }, []);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="select-none sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-0 text-center text-4xl font-semibold uppercase leading-[48px] tracking-tight text-gray-900">
          {t('Pages.Auth.welcome')}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <LogInForm />
      </div>
    </div>
  );
}
