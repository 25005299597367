'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PhoneInput,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { profileInfoValidationSchema, ProfileInfoValidationSchema } from './profile-info-validation-schema';
import { cn } from '@/lib/utils';
import { useCallback } from 'react';
import lodash from 'lodash';
import { ProfileAccountInfoForm } from '../interfaces/profile.interface';
import { PHONE_COUNTRIES } from '@/common/constants/common';

interface ProfileInfoFormProps {
  account: ProfileAccountInfoForm;
  onChanges: (account: ProfileAccountInfoForm) => void;
}

export function ProfileInfoForm({ account, onChanges }: ProfileInfoFormProps) {
  const t = useTranslations();

  const form = useForm<ProfileInfoValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(profileInfoValidationSchema),
    defaultValues: {
      firstName: account.firstName,
      lastName: account.lastName,
      phoneNumber: account.phoneNumber,
      role: account.role,
    },
  });

  const onChangeHandler = useCallback(
    lodash.debounce(() => onChanges(form.getValues()), 300),
    []
  );

  return (
    <Form {...form}>
      <h2 className="my-6 text-sm font-semibold text-gray-950">{t('Common.personalInformation')}</h2>
      <form noValidate onChange={onChangeHandler} className="grid grid-cols-2 gap-8">
        <div className="relative">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel isRequired>{t('Forms.firstName.label')}</FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    placeholder={t('Forms.firstName.placeholder')}
                    {...field}
                    type="text"
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative">
          <FormField
            control={form.control}
            name="lastName"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel isRequired>{t('Forms.lastName.label')}</FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="text"
                    placeholder={t('Forms.lastName.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative">
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>{t('Forms.phoneNumber.label')}</FormLabel>
                <FormControl>
                  <PhoneInput countries={PHONE_COUNTRIES} placeholder={t('Forms.phoneNumber.placeholder')} {...field} value={field.value ?? ''} className="w-full" hasError={!!fieldState.error} />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative">
          <FormField
            control={form.control}
            name="role"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>{t('Forms.accessLevel.label')}</FormLabel>
                <FormControl>
                  <Select disabled value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                        <SelectValue
                          placeholder={t('Forms.accessLevel.placeholder')}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                     {account && account.role && <SelectItem value={account.role}>{account.role}</SelectItem>}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
}
