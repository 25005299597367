import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { MyClient } from '@/views/my-clients/interfaces/my-clients.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, MyClient | null>, 'queryKey' | 'queryFn'>;

export const useMyClientQuery = (serviceId?: string, options?: Options) => {
  const _query = async (): Promise<MyClient | null> => {
    if (serviceId) {
      const { data } = await axiosInterceptorInstance.get(`services/me/clients/${serviceId}`);
      return data;
    }
    return null;
  };

  return useQuery({
    queryKey: ['services', 'me', 'clients', serviceId],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
