/* eslint-disable max-lines */
'use client';

import { useEffect, useRef, useState } from 'react';
import { Button, Popover, PopoverContent, PopoverTrigger } from '@/components/ui';
import { DateInput } from './date-input';
import dayjs from '@/lib/dayjsConfig';
import { Calendar } from '@/components/ui/calendar';

export interface DateRangePickerProps {
  /** Click handler for applying the updates from DateRangePicker. */
  onUpdate?: (values: { range: DateRange }) => void;
  /** Initial value for start date */
  initialDateFrom?: Date | string;
  /** Initial value for end date */
  initialDateTo?: Date | string;
  /** Initial value for start date for compare */
  initialCompareFrom?: Date | string;
  /** Initial value for end date for compare */
  initialCompareTo?: Date | string;
  /** Alignment of popover */
  align?: 'start' | 'center' | 'end';
  /** Option for locale */
  locale?: string;
  /** Option for showing compare feature */
  showCompare?: boolean;
}

const formatDate = (date: Date, locale: string = 'en-us'): string => {
  // return date.toLocaleDateString(locale);
  return dayjs(date).format('MM.DD.YYYY');
};

const getDateAdjustedForTimezone = (dateInput: Date | string): Date => {
  if (typeof dateInput === 'string') {
    // Split the date string to get year, month, and day parts
    const parts = dateInput.split('-').map((part) => parseInt(part, 10));
    // Create a new Date object using the local timezone
    // Note: Month is 0-indexed, so subtract 1 from the month part
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    return date;
  } else {
    // If dateInput is already a Date object, return it directly
    return dateInput;
  }
};

export interface DateRange {
  from: Date | undefined;
  to: Date | undefined;
}

interface Preset {
  name: string;
  label: string;
}

// Define presets
const PRESETS: Preset[] = [
  { name: 'today', label: 'Today' },
  { name: 'yesterday', label: 'Yesterday' },
  { name: 'last7', label: 'Last 7 days' },
  { name: 'last14', label: 'Last 14 days' },
  { name: 'last30', label: 'Last 30 days' },
  { name: 'thisWeek', label: 'This Week' },
  { name: 'lastWeek', label: 'Last Week' },
  { name: 'thisMonth', label: 'This Month' },
  { name: 'lastMonth', label: 'Last Month' },
];

/** The DateRangePicker component allows a user to select a range of dates */
export const DateRangePicker = ({
  initialDateFrom,
  initialDateTo,
  onUpdate,
  align = 'end',
  locale = 'en-US',
}: DateRangePickerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const [range, setRange] = useState<DateRange>({
    from: initialDateFrom ? getDateAdjustedForTimezone(initialDateFrom) : undefined,
    to: initialDateTo ? getDateAdjustedForTimezone(initialDateTo) : undefined,
  });

  const openedRangeRef = useRef<DateRange | undefined>();

  const [isSmallScreen, setIsSmallScreen] = useState(typeof window !== 'undefined' ? window.innerWidth < 960 : false);

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 960);
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const resetValues = (): void => {
    setRange({
      from: typeof initialDateFrom === 'string' ? getDateAdjustedForTimezone(initialDateFrom) : initialDateFrom,
      to: initialDateTo
        ? typeof initialDateTo === 'string'
          ? getDateAdjustedForTimezone(initialDateTo)
          : initialDateTo
        : typeof initialDateFrom === 'string'
          ? getDateAdjustedForTimezone(initialDateFrom)
          : initialDateFrom,
    });
  };

  // Helper function to check if two date ranges are equal
  const areRangesEqual = (a?: DateRange, b?: DateRange): boolean => {
    if (!a || !b) return a === b; // If either is undefined, return true if both are undefined
    return a.from?.getTime() === b.from?.getTime() && (!a.to || !b.to || a.to.getTime() === b.to.getTime());
  };

  useEffect(() => {
    if (isOpen) {
      openedRangeRef.current = range;
    }
  }, [isOpen]);

  return (
    <Popover
      modal={true}
      open={isOpen}
      onOpenChange={(open: boolean) => {
        if (!open && !range.to) {
          resetValues();
          setRange({ to: undefined, from: undefined });
        }
        setIsOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button className="h-10 w-full pl-3 text-left font-normal" size={'lg'} variant="outline">
          <div className="text-right">
            <div className="py-1">
              {range.from ? (
                <div>{`${formatDate(range.from, locale)}${
                  range.to != null ? ' - ' + formatDate(range.to, locale) : ''
                }`}</div>
              ) : (
                'MM.DD.YYYY - MM.DD.YYYY'
              )}
            </div>
          </div>
          {/* <div className="-mr-2 scale-125 pl-1 opacity-60">
              {isOpen ? <ChevronUpIcon width={24} /> : <ChevronDownIcon width={24} />}
            </div> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent align={align} className="w-auto">
        <div className="flex py-2">
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex flex-col items-center justify-end gap-2 px-3 pb-4 lg:flex-row lg:items-start lg:pb-0">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <DateInput
                      value={range.from}
                      onChange={(date) => {
                        const toDate = range.to == null || date > range.to ? date : range.to;
                        setRange((prevRange) => ({
                          ...prevRange,
                          from: date,
                          to: toDate,
                        }));
                      }}
                    />
                    <div className="py-1">-</div>
                    <DateInput
                      value={range.to}
                      onChange={(date) => {
                        if (range.from) {
                          const fromDate = date < range.from ? date : range.from;
                          setRange((prevRange) => ({
                            ...prevRange,
                            from: fromDate,
                            to: date,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <Calendar
                  mode="range"
                  onSelect={(value: { from?: Date; to?: Date } | undefined) => {
                    if (value?.from != null) {
                      setRange({ from: value.from, to: value?.to });
                    }
                  }}
                  selected={range}
                  numberOfMonths={isSmallScreen ? 1 : 2}
                  defaultMonth={new Date(new Date().setMonth(new Date().getMonth() - (isSmallScreen ? 0 : 1)))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-2 py-2 pr-4">
          <Button
            type="button"
            onClick={() => {
              setIsOpen(false);
              resetValues();
            }}
            size="sm"
            variant="outline"
          >
            Cancel
          </Button>

          <div className="flex flex-row items-center gap-2">
            <Button
              type="button"
              onClick={() => {
                setIsOpen(false);
                setRange({ to: undefined, from: undefined });
              }}
              variant="outlineDestructive"
              size="sm"
            >
              Clear
            </Button>

            <Button
              type="button"
              disabled={!range.to}
              size="sm"
              onClick={() => {
                setIsOpen(false);
                if (!areRangesEqual(range, openedRangeRef.current)) {
                  onUpdate?.({ range });
                }
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

DateRangePicker.displayName = 'DateRangePicker';
