'use client';

import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ForgotPasswordValidationSchema, forgotPasswordValidationSchema } from './forgot-pass-validation-schema';
import { Button } from '@/components/ui';
import { Input, Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/form';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useForgotPassMutation } from '@/hooks/fetchers/mutations/auth/useForgotPassMutation';

export interface FormValues {
  email: string;
}

export function ForgotPasswordForm() {
  const t = useTranslations();
  const router = useRouter();

  const { mutate: restorePassword, isPending } = useForgotPassMutation({
    onSuccess: () => {
      router.push(`/auth/forgot-password/confirmation?email=${form.getValues().email}`);
      form.reset();
    }
  })

  const form = useForm<ForgotPasswordValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(forgotPasswordValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<ForgotPasswordValidationSchema> = (data: ForgotPasswordValidationSchema): void => {
    restorePassword(data.email);
  };

  useEffect(() => {
    form.setFocus('email');
  }, []);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <div className="relative mb-10">
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel isRequired>{t('Forms.email.label')}</FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    placeholder={t('Forms.email.placeholder')}
                    {...field}
                    type="text"
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <Button type="submit" size="xl" className="mt-8 w-full" disabled={isPending}>
          {isPending ? t('Common.loadingWait') : t('Buttons.continue')}
        </Button>
      </form>
    </Form>
  );
}
