'use client';

import { Button } from '@/components/ui';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useTranslations } from 'next-intl';
import dayjs from '@/lib/dayjsConfig';
import { useEffect, useState } from 'react';

interface Props {
  onChange: (value: { start: dayjs.Dayjs; end: dayjs.Dayjs }) => void;
}

export function ScheduleHeader({ onChange }: Props) {
  const t = useTranslations();

  const [currentWeek, setCurrentWeek] = useState<{ start: dayjs.Dayjs; end: dayjs.Dayjs }>({
    start: dayjs().startOf('week'),
    end: dayjs().endOf('week'),
  });

  useEffect(() => {
    onChange(currentWeek);
  }, [JSON.stringify(currentWeek)]);

  function reset() {
    setCurrentWeek({
      start: dayjs().startOf('week'),
      end: dayjs().endOf('week'),
    });
  }

  function nextWeeks() {
    setCurrentWeek((prev) => {
      const nextStartWeek = prev.start.add(1, 'week');
      return {
        start: nextStartWeek,
        end: nextStartWeek.endOf('week'),
      };
    });
  }

  function prevWeeks() {
    setCurrentWeek((prev) => {
      const prevStartWeek = prev.start.add(-1, 'week');
      return {
        start: prevStartWeek,
        end: prevStartWeek.endOf('week'),
      };
    });
  }

  function isWeekToday(): boolean {
    const today = dayjs();
    return today.isBetween(currentWeek.start, currentWeek.end, 'day', '[]');
  }

  return (
    <>
      <nav className="flex w-full flex-row items-center justify-between pl-4 pr-6">
        <h1 className="mt-2 py-4 text-2xl font-bold">{t('Pages.Schedule.title')}</h1>
        <span className="text-[28px] font-normal text-gray-800">{dayjs(currentWeek.start).format('MMMM YYYY')}</span>
        <div className="flex flex-row items-center space-x-4">
          <Button variant="ghost" size="icon" onClick={prevWeeks}>
            <ChevronLeft />
          </Button>
          <Button variant="ghost" size="icon" onClick={nextWeeks}>
            <ChevronRight />
          </Button>

          <Button variant="outline" size="lg" disabled={isWeekToday()} onClick={reset}>
            {t('Buttons.today')}
          </Button>
        </div>
      </nav>
    </>
  );
}
