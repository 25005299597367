import { ColumnDef } from '@tanstack/react-table';
import { compileFullName, getColorForServiceRole } from '@/lib/utils';
import dayjs from '@/lib/dayjsConfig';
import { Signature } from '../interfaces/signature.interface';
import { Chips } from '@/components/common';
import { Colors } from '@/common/enums';
import Image from 'next/image';
import { SHIFT_STATUS_COLORS, USER_ROLES_COLORS } from '@/common/constants/chips-colors';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';

type Params = {
  t: any;
};

export const getSignaturesColumns = ({ t }: Params): ColumnDef<Signature>[] => {
  const columns: ColumnDef<Signature>[] = [
    {
      id: 'Service',
      accessorKey: 'service',
      header: () => <span>{t('Tables.service')}</span>,
      cell: ({ row }) => {
        if (row.original.service) {
          return <Chips color={getColorForServiceRole(row.original.serviceRole.title, row.original.serviceRole.speciality.code)} title={row.original.service.name} />;
        }
        return <></>;
      },
      size: 250,
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'Guardian Signature',
      header: () => <span>{t('Tables.guardianSignature')}</span>,
      cell: ({ row }) => {
        if (row.original.clientSignatureUrl) {
          return <Image src={row.original.clientSignatureUrl} width={110} height={56} alt="Client signature" />;
        }
        return <></>;
      },
      size: 250,
      enableSorting: false,
      enablePinning: false,
    },
    {
      id: 'Hours Worked',
      accessorKey: 'hoursWorked',
      accessorFn: (row) => (row.hoursWorked ? row.hoursWorked : ''),
      header: () => <span>{t('Tables.hoursWorked')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 150,
    },
    {
      id: 'Client',
      accessorKey: 'client',
      header: () => <span>{t('Tables.client')}</span>,
      cell: ({ row }) => {
        if (row.original.client) {
          return <Chips color={Colors.indigo} title={compileFullName(row.original.client)} />;
        }
        return <></>;
      },
      size: 250,
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'Service Role',
      header: () => <span>{t('Tables.serviceRole')}</span>,
      cell: ({ row }) => {
        if (row.original.serviceRole) {
          const sr = row.original.serviceRole;
          return <Chips color={getColorForServiceRole(sr.title, sr.speciality.code)} title={sr.title} />;
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Therapist',
      accessorKey: 'type',
      header: () => <span>{t('Tables.therapist')}</span>,
      cell: ({ row }) => {
        if (row.original.therapist) {
          return <Chips color={USER_ROLES_COLORS[RoleTypes.Therapist]} title={compileFullName(row.original.therapist)} />;
        }
        return <></>;
      },
      size: 250,
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'Date',
      accessorKey: 'date',
      accessorFn: (row) => dayjs(row.date).format('MM.DD.YYYY'),
      header: () => <span>{t('Tables.date')}</span>,
      enableSorting: true,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'Clock in Time',
      accessorKey: 'clockIn',
      accessorFn: (row) => (row.clockIn ? dayjs(row.clockIn).utcOffset(dayjs().format('Z')).format('h:mm A') : ''),
      header: () => <span>{t('Tables.clockInTime')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'Clock out Time',
      accessorKey: 'clockOut',
      accessorFn: (row) => (row.clockOut ? dayjs(row.clockOut).utcOffset(dayjs().format('Z')).format('h:mm A') : ''),
      header: () => <span>{t('Tables.clockOutTime')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'Start Time',
      accessorKey: 'startAt',
      accessorFn: (row) => (row.startAt ? dayjs(row.startAt).utcOffset(dayjs().format('Z')).format('h:mm A') : ''),
      header: () => <span>{t('Tables.startTime')}</span>,
      enableSorting: true,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'End Time',
      accessorKey: 'endAt',
      accessorFn: (row) => (row.endAt ? dayjs(row.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : ''),
      header: () => <span>{t('Tables.endTime')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'Status',
      accessorKey: 'status',
      header: () => <span>{t('Tables.status')}</span>,
      cell: ({ row }) => {
        if (row.original.status) {
          return <Chips color={SHIFT_STATUS_COLORS[row.original.status]} title={row.original.status} />;
        } else {
          return <></>;
        }
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
  ];

  return columns;
};
