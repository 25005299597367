import { SPECIAL_AND_NUMBERS, UPPER_AND_LOWER_CASE } from '@/common/constants/regex-constants';
import { z } from 'zod';

export const changePasswordValidationSchema = z
  .object({
    currentPassword: z.string().min(1, { message: 'Forms.required' }),
    newPassword: z.string().min(1, { message: 'Forms.required' }).min(8, 'Forms.password.min'),
    confirmPassword: z.string().min(1, { message: 'Forms.required' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Forms.passwordMismatch',
  })
  .refine((data) => data.newPassword.match(UPPER_AND_LOWER_CASE), {
    path: ['password'],
    message: 'Forms.password.upperAndLowerCase',
  })
  .refine((data) => data.newPassword.match(SPECIAL_AND_NUMBERS), {
    path: ['password'],
    message: 'Forms.password.numbersSpecialChar',
  });

export type ChangePasswordValidationSchema = z.infer<typeof changePasswordValidationSchema>;
