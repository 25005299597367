'use client';
import { BackButton, toast, ToastTypeEnums } from '@/components/common';
import { Button } from '@/components/ui';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useTranslations } from 'next-intl';
import { ProfileInfoForm } from './profile-info/profile-info-form';
import { ChangePasswordForm } from './change-password/change-pass-form';
import { ChangeEmailForm } from './change-email/change-email-form';
import { useRef, useState } from 'react';
import { signOut } from 'next-auth/react';
import {
  ProfileAccountInfoForm,
  ProfileChangeEmailForm,
  ProfileChangePasswordForm,
} from './interfaces/profile.interface';
import { BtnActionTypes } from './enums/btn-action-types';
import { useMyProfileQuery } from '@/hooks/fetchers/queries/me/useMyProfileQuery';
import { useUpdateMyEmailMutation } from '@/hooks/fetchers/mutations/me/useUpdateMyEmailMutation';
import { useUpdateMyPasswordMutation } from '@/hooks/fetchers/mutations/me/useUpdateMyPasswordMutation';
import { useUpdateMyProfileMutation } from '@/hooks/fetchers/mutations/me/useUpdateMyProfileMutation';

const CALLBACK_URL = `${process.env.NEXT_PUBLIC_LOGIN_URL}`;

export function MyProfileView() {
  const t = useTranslations();
  const [btnAction, setBtnAction] = useState(BtnActionTypes.INFO);
  const accountRef = useRef<ProfileAccountInfoForm>();
  const passwordDataRef = useRef<ProfileChangePasswordForm>();
  const emailDataRef = useRef<ProfileChangeEmailForm>();

  const { data: account, isSuccess } = useMyProfileQuery();

  const { mutate: updateAccount, isPending: isUpdatingInfo } = useUpdateMyProfileMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successProfileUpdated'),
      });
    },
  });

  const { mutate: updatePassword, isPending: isUpdatingPassword } = useUpdateMyPasswordMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.passwordSuccessfullyUpdated'),
      });
      signOut({ callbackUrl: CALLBACK_URL });
    },
  });

  const { mutate: updateEmail, isPending: isUpdatingEmail } = useUpdateMyEmailMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.emailSuccessfullyUpdated'),
      });
      signOut({ callbackUrl: CALLBACK_URL });
    },
  });

  const handleChangeAccount = (account: ProfileAccountInfoForm) => {
    accountRef.current = account;
  };

  const handleChangePassword = (passwordData: ProfileChangePasswordForm) => {
    passwordDataRef.current = passwordData;
  };

  const handleChangeEmail = (emailData: ProfileChangeEmailForm) => {
    emailDataRef.current = emailData;
  };

  const handleSubmit = () => {
    switch (btnAction) {
      case BtnActionTypes.INFO:
        if (accountRef.current) {
          const { firstName, lastName, phoneNumber } = accountRef.current;
          const phone = phoneNumber ? phoneNumber.replace('+', '') : '';
          updateAccount({
            firstName,
            lastName,
            phoneNumber: phone === '' ? null : phone,
          });
        }
        break;
      case BtnActionTypes.EMAIL:
        if (emailDataRef.current) {
          if (emailDataRef.current) {
            updateEmail(emailDataRef.current);
          }
        }
        break;
      case BtnActionTypes.PASSWORD:
        if (passwordDataRef.current) {
          updatePassword(passwordDataRef.current);
        }
        break;
      default:
        break;
    }
  };

  return (
    <main className="relative grid h-[calc(100vh-64px)] grid-rows-[1fr_100px]">
      <BackButton />
      <div className="flex flex-col items-center justify-center">
        <div className="h-full w-full max-w-[604px] py-11">
          <h1 className="text-2xl font-bold">{t('Pages.MyProfile.myProfile')}</h1>

          <Tabs
            onValueChange={(value) => setBtnAction(value as BtnActionTypes)}
            defaultValue={BtnActionTypes.INFO}
            className="mt-8"
          >
            <TabsList className="w-full">
              <TabsTrigger className="w-full" value={BtnActionTypes.INFO}>
                {t('Buttons.profileInfo')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={BtnActionTypes.PASSWORD}>
                {t('Buttons.changePassword')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={BtnActionTypes.EMAIL}>
                {t('Buttons.changeEmail')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value={BtnActionTypes.INFO}>
              {isSuccess && account && <ProfileInfoForm onChanges={handleChangeAccount} account={account} />}
            </TabsContent>
            <TabsContent value={BtnActionTypes.PASSWORD}>
              <ChangePasswordForm onChanges={handleChangePassword} />
            </TabsContent>
            <TabsContent value={BtnActionTypes.EMAIL}>
              <ChangeEmailForm onChanges={handleChangeEmail} />
            </TabsContent>
          </Tabs>
        </div>
      </div>
      <footer className="ml-[-10px] flex flex-row items-center justify-end space-x-6 border-t border-gray-300 px-6">
        <Button
          onClick={handleSubmit}
          disabled={isUpdatingInfo || isUpdatingPassword || isUpdatingEmail}
          className="w-44"
          variant="default"
          size="lg"
        >
          {t(`Buttons.${btnAction}`)}
        </Button>
      </footer>
    </main>
  );
}
