'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/form';
import { useTranslations } from 'next-intl';
import { changeEmailValidationSchema, ChangeEmailValidationSchema } from './change-email-validation-schema';
import lodash from 'lodash';
import { useCallback } from 'react';
import { ProfileChangeEmailForm } from '../interfaces/profile.interface';

interface ChangeEmailFormProps {
  onChanges: (data: ProfileChangeEmailForm) => void;
}

export function ChangeEmailForm({onChanges}: ChangeEmailFormProps) {
  const t = useTranslations();

  const form = useForm<ChangeEmailValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(changeEmailValidationSchema),
    defaultValues: {
      currentEmail: '',
      password: '',
      newEmail: '',
    },
  });

  const onChangeHandler = useCallback(lodash.debounce(() => onChanges(form.getValues()), 300), []);

  return (
    <Form {...form}>
      <h2 className="mt-6 text-sm font-semibold text-gray-950">{t('Pages.MyProfile.changeEmail')}</h2>
      <p className="mb-6 mt-3 text-base font-light text-gray-400">{t('Pages.MyProfile.changeEmailRule')}</p>
      <form noValidate onChange={onChangeHandler} className="grid grid-cols-1 gap-8">
        <div className="relative max-w-72">
          <FormField
            control={form.control}
            name="currentEmail"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel className="block first-letter:uppercase" isRequired>
                  {t('Forms.currentEmail.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="email"
                    placeholder={t('Forms.currentEmail.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative max-w-72">
          <FormField
            control={form.control}
            name="newEmail"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel className="block first-letter:uppercase" isRequired>
                  {t('Forms.newEmail.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="email"
                    placeholder={t('Forms.email.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative max-w-72">
        <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel className="block first-letter:uppercase" isRequired>
                  {t('Forms.password.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="password"
                    autoComplete="current-password"
                    placeholder={t('Forms.password.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
}
