import { ALLOWED_MESSAGE_FILES } from '@/common/constants/common';
import { Input } from '@/components/form';
import { Button } from '@/components/ui';
import { useFilePicker } from '@/lib/file-chooser';
import { FileAmountLimitValidator, FileSizeValidator, FileTypeValidator } from '@/lib/file-chooser/validators';
import { Paperclip, SendHorizontal } from 'lucide-react';
import { useState } from 'react';

interface MessageInputProps {
  value: any;
  onChange: (value: any) => void;
  sendMessage: () => void;
  sendFile: (value: File[]) => void;
}



export function MessageInput({ value, onChange, sendMessage, sendFile }: MessageInputProps) {
  const [textValue, setTextValue] = useState('');

  const { openFilePicker, filesContent, loading, errors, plainFiles, clear } = useFilePicker({
    multiple: true,
    readAs: 'DataURL', // available formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: [...ALLOWED_MESSAGE_FILES.map(type => `.${type}`), ...ALLOWED_MESSAGE_FILES.map(type => `.${type.toUpperCase()}`)],
    validators: [
      new FileAmountLimitValidator({ min: 1, max: 1 }),
      new FileSizeValidator({ maxFileSize: 24 * 1024 * 1024 /* 24 MB */ }),
      new FileTypeValidator([...ALLOWED_MESSAGE_FILES.map(type => `${type}`), ...ALLOWED_MESSAGE_FILES.map(type => `${type.toUpperCase()}`)]),
    ],
    onFilesRejected: ({ errors }) => {
      console.log('onFilesRejected', errors);
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => sendFile(plainFiles),
  });

  function isNullOrWhitespace(input: string) {
    return !input || input.trim().length === 0;
  }

  const handleEnterPress = (event: any, callback: any) => {
    if (event.key === 'Enter') {
      if (!isNullOrWhitespace(textValue)) {
        callback();
        setTextValue('');
      } else {
        event.stopPropagation();
      }
    }
  };

  return (
    <div className="flex min-h-16 w-full flex-row items-center justify-between border-t border-gray-300 bg-white px-3">
      <Input
        value={value}
        onChange={(e) => {
          onChange(e);
          setTextValue(e.target.value);
        }}
        onKeyDown={(event) => handleEnterPress(event, sendMessage)}
        placeholder="Type message"
      ></Input>

      {textValue.length > 0 && (
        <Button className="ml-2 min-w-9" type="submit" variant="ghost" size="icon" onClick={(e) => {
          if (!isNullOrWhitespace(textValue)) {
            sendMessage();
            setTextValue('');
          } else {
            e.stopPropagation();
          }
        }}>
          <SendHorizontal />
        </Button>
      )}

      {textValue.length === 0 && (
        <Button className="ml-2 min-w-9" type="submit" variant="ghost" size="icon" onClick={openFilePicker}>
          <Paperclip />
        </Button>
      )}
    </div>
  );
}
