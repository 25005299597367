'use client';
import { useTranslations } from 'next-intl';
import { AllDocumentsTable } from './table/all-documents-table';

export function AllDocumentsView() {
  const t = useTranslations();

  return (
    <main className='pl-4 pr-6'>
      <h1 className='font-bold text-2xl py-4 mt-2'>{t('Pages.AllDocuments.title')}</h1>
      <AllDocumentsTable />
    </main>
  );
}
