import { Colors } from '@/common/enums';
import { Chips } from '@/components/common';
import { cn, compileFullName } from '@/lib/utils';
import dayjs from '@/lib/dayjsConfig';
import Image from 'next/image';
import { AuditLog, User } from './audit-log.interface';
import { ACTIVITY_ACTION_TYPES, ACTIVITY_ENTITY_TYPES } from '@/common/constants/common';
import { useTranslations } from 'next-intl';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';

interface Props {
  value: AuditLog;
  onPreview: (log: AuditLog) => void;
  onPreviewUser: (user: User) => void;
  onPreviewEntity: (log: AuditLog) => void;
}

const clickableEntity = [
  ACTIVITY_ENTITY_TYPES.CLIENTS,
  ACTIVITY_ENTITY_TYPES.CLIENT_GUARDIANS,
  ACTIVITY_ENTITY_TYPES.DOCUMENTS,
  ACTIVITY_ENTITY_TYPES.SERVICES,
  ACTIVITY_ENTITY_TYPES.SERVICE_SHIFTS,
  ACTIVITY_ENTITY_TYPES.SHIFTS,
  ACTIVITY_ENTITY_TYPES.SERVICE_SHIFTS,
  ACTIVITY_ENTITY_TYPES.THERAPIST_RATES,
  ACTIVITY_ENTITY_TYPES.THERAPIST_SUPERVISORS,
  ACTIVITY_ENTITY_TYPES.THERAPIST_WORK_LOGS,
];

export function AuditLogItem({ value, onPreview, onPreviewUser, onPreviewEntity }: Props) {
  const t = useTranslations();

  return (
    <div
      onClick={() => onPreview(value)}
      className="flex min-h-16 cursor-pointer flex-row items-start border-b border-blue-100"
    >
      {/* <Image
        className="ml-2 mt-2 rounded-full border border-gray-200 p-2"
        width={44}
        height={44}
        alt="Event Icon"
        src={svgPath}
      /> */}
      <div className="relative flex w-full flex-col justify-start bg-white p-3">
        <header className="flex flex-row items-center justify-start">
          {value.user ? (
            <Chips
              color={USER_ROLES_COLORS[value.user.role]}
              title={compileFullName(value.user)}
              onClickAction={(e) => {
                onPreviewUser(value.user as User);
                e.stopPropagation();
              }}
            />
          ) : (
            <Chips color={Colors.gray} title={`System log`} />
          )}
        </header>
        <div className="mt-1">
          <span className="text-sm font-light text-gray-600">
            {value.action === ACTIVITY_ACTION_TYPES.CREATE && (
              <>
                {t('Pages.AuditLog.created')}{' '}
                {clickableEntity.includes(value.entity) ? (
                  <strong
                    onClick={(e) => {
                      onPreviewEntity(value);
                      e.stopPropagation();
                    }}
                    className="cursor-pointer text-primary underline"
                  >
                    {value.entity}
                  </strong>
                ) : (
                  value.entity
                )}
              </>
            )}
            {value.action === ACTIVITY_ACTION_TYPES.UPDATE && (
              <>
                {t('Pages.AuditLog.updated')}{' '}
                {clickableEntity.includes(value.entity) ? (
                  <strong
                    onClick={(e) => {
                      onPreviewEntity(value);
                      e.stopPropagation();
                    }}
                    className="cursor-pointer text-primary underline"
                  >
                    {value.entity}
                  </strong>
                ) : (
                  value.entity
                )}
              </>
            )}
            {value.action === ACTIVITY_ACTION_TYPES.SOFT_DELETE && (
              <>
                {t('Pages.AuditLog.softDeleted')}{' '}
                {clickableEntity.includes(value.entity) ? (
                  <strong
                    onClick={(e) => {
                      onPreviewEntity(value);
                      e.stopPropagation();
                    }}
                    className="cursor-pointer text-primary underline"
                  >
                    {value.entity}
                  </strong>
                ) : (
                  value.entity
                )}
              </>
            )}
            {value.action === ACTIVITY_ACTION_TYPES.DEACTIVATE && (
              <>
                {t('Pages.AuditLog.deactivate')}{' '}
                {clickableEntity.includes(value.entity) ? (
                  <strong
                    onClick={(e) => {
                      onPreviewEntity(value);
                      e.stopPropagation();
                    }}
                    className="cursor-pointer text-primary underline"
                  >
                    {value.entity}
                  </strong>
                ) : (
                  value.entity
                )}
              </>
            )}
            {value.action === ACTIVITY_ACTION_TYPES.RESTORE && (
              <>
                {t('Pages.AuditLog.restored')}{' '}
                {clickableEntity.includes(value.entity) ? (
                  <strong
                    onClick={(e) => {
                      onPreviewEntity(value);
                      e.stopPropagation();
                    }}
                    className="cursor-pointer text-primary underline"
                  >
                    {value.entity}
                  </strong>
                ) : (
                  value.entity
                )}
              </>
            )}
          </span>
        </div>
        <span className="absolute right-4 top-4 text-xs text-gray-500">
          {dayjs(value.createdAt).format('MMM D, h:mm A')}
        </span>
      </div>
    </div>
  );
}
