import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Response = {
  id: string;
};

export type Options = Omit<
  UseMutationOptions<Response, DefaultError, string>,
  'mutationKey' | 'mutationFn'
>;

export const useRemoveServiceRoleMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (id: string): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.delete(`service-roles/${id}`);
    return data;
  };

  return useMutation({
    mutationKey: ['service-roles'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['service-roles'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    ...options,
  });
};
