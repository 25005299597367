'use client';

import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LogInValidationSchema, logInValidationSchema } from './login-validation-schema';
import { Button } from '@/components/ui';
import { Input, Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/form';
import { useTranslations } from 'next-intl';
import SvgGoogleIcon from '@/components/svg/GoogleIcon';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { toast, ToastTypeEnums } from '@/components/common';
import { signIn } from 'next-auth/react';
import { DEFAULT_LOGIN_REDIRECT } from '@/routes';
import { useQueryClient } from '@tanstack/react-query';

export function LogInForm() {
  const t = useTranslations();
  const queryClient = useQueryClient();
  const router = useRouter();
  const searchParams = useSearchParams();

  const form = useForm<LogInValidationSchema>({
    resolver: zodResolver(logInValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<LogInValidationSchema> = async (data: LogInValidationSchema) => {
    queryClient.clear();
    const { email, password } = data;

    try {
      const response: any = await signIn('credentials', {
        email,
        password,
        redirect: false,
      });

      if (!response.ok) {
        if (response.status === 401) {
          toast({
            typeIcon: ToastTypeEnums.WARNING,
            title: t('Toasts.failed'),
            description: response.error ? response.error : t('Toasts.loginError'),
          });
        } else {
          throw response.error;
        }
      } else {
        form.reset();
        const callbackUrl = searchParams.get('callbackUrl');
        router.push(callbackUrl ? callbackUrl : DEFAULT_LOGIN_REDIRECT);
        router.refresh();
      }
    } catch (error: any) {
      console.log(error);

      toast({
        title: t('Toasts.failed'),
        description: error,
        typeIcon: ToastTypeEnums.WARNING,
      });
    }
  };

  const handleSubmitViaGoogle = () => {
    queryClient.clear();
    signIn('google', {
      callbackUrl: DEFAULT_LOGIN_REDIRECT,
    });
  };

  useEffect(() => {
    form.setFocus('email');
  }, []);

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="relative mb-10">
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel isRequired>{t('Forms.email.label')}</FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    placeholder={t('Forms.email.placeholder')}
                    {...field}
                    type="email"
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative mb-2">
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel isRequired>{t('Forms.password.label')}</FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="password"
                    placeholder={t('Forms.password.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="mt-0 flex items-center justify-end">
          <div className="text-sm">
            <Link className="text-sm font-normal text-blue-700 hover:text-blue-500" href="/auth/forgot-password">
              {t('Buttons.forgotPassword')}
            </Link>
          </div>
        </div>

        <Button type="submit" size="xl" className="mt-8 w-full" disabled={form.formState.isSubmitting}>
          {form.formState.isSubmitting ? t('Common.loadingWait') : t('Buttons.signIn')}
        </Button>

        <h6 className="my-4 w-full text-center text-sm font-normal text-gray-600">{t('Pages.Auth.or')}</h6>

        <Button
          onClick={handleSubmitViaGoogle}
          type="button"
          size="xl"
          variant="outline"
          className="w-full text-base text-gray-700"
        >
          <SvgGoogleIcon height="24" width="24" className="mr-2" />
          {t('Buttons.signInWithGoogle')}
        </Button>
      </form>
    </Form>
  );
}
