import { cn } from '@/lib/utils';
import { Message } from './message';
import dayjs from '@/lib/dayjsConfig';

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SendbirdChatWith } from '@sendbird/chat';
import { GroupChannelModule, GroupChannel } from '@sendbird/chat/groupChannel';
import { BaseMessage, UserMessage } from '@sendbird/chat/message';
import { ChatTypes } from '../channel/channel-list';
import { useDepartmentQuery } from '@/hooks/fetchers/queries/departments/useDepartmentQuery';

interface MessagesListProps {
  messages: BaseMessage[] & UserMessage[];
  readMessages: BaseMessage[] & UserMessage[];
  currentlyJoinedChannel: GroupChannel | null;
  sb: SendbirdChatWith<GroupChannelModule[]>;
  handleDeleteMessage: (message: BaseMessage & UserMessage) => void;
  updateMessage: (message: BaseMessage & UserMessage) => void;
  previewImage?: (url: string) => void;
}

export type MessageListRef = {
  scrollToBottom: (isSmooth?: boolean) => void;
};

const MessagesList = forwardRef<MessageListRef, MessagesListProps>(
  ({ sb, messages, handleDeleteMessage, updateMessage, readMessages, currentlyJoinedChannel }, ref) => {
    const [departmentId, setDepartmentId] = useState<string>();

    const scrollRef = useRef<HTMLUListElement | null>(null);
    useImperativeHandle(ref, () => ({
      scrollToBottom: (isSmooth: boolean = true) => {
        if (scrollRef.current) {
          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight,
            behavior: isSmooth ? 'smooth' : 'instant',
          });
        }
      },
    }));

    const { data: departmentDetails, refetch: refetchDepartment } = useDepartmentQuery(departmentId, {
      enabled: false,
    });

    useEffect(() => {
      if (currentlyJoinedChannel && currentlyJoinedChannel.customType === ChatTypes.DEPARTMENT) {
        const departmentId = currentlyJoinedChannel.data
          ? JSON.parse(currentlyJoinedChannel.data).departmentId
          : undefined;
        setDepartmentId(departmentId);
      }
    }, [currentlyJoinedChannel]);

    useEffect(() => {
      if (departmentId) {
        refetchDepartment();
      }
    }, [departmentId]);

    const compareDate = (current: number, previous: number): boolean => {
      const prevDate = new Date(previous).toLocaleDateString('en-US');
      const currentDate = new Date(current).toLocaleDateString('en-US');
      return prevDate !== currentDate;
    };

    const dateSeparator = (date: string): string => {
      if (dayjs(date).isToday()) {
        return 'today';
      } else if (dayjs(date).isYesterday()) {
        return 'yesterday';
      } else {
        return dayjs(date).format('MM/DD/YY');
      }
    };

    let isAdmin = false;

    if (departmentDetails) {
      const adminsIds = departmentDetails.users.map((item) => item.id);
      isAdmin = adminsIds.includes(sb.currentUser?.userId as string);
    }

    return (
      <ul ref={scrollRef} className="h-[calc(100vh-184px)] overflow-scroll">
        {messages.map((message: any, i: number) => {
          let messageSentByYou;

          if (message.messageType === 'admin') {
            messageSentByYou = false;
          } else {
            messageSentByYou = message.sender.userId === sb.currentUser?.userId;
          }

          return (
            <li key={`${message.messageId}_${i}`}>
              {messages[i - 1] && compareDate(message.createdAt, messages[i - 1].createdAt) && (
                <div className="my-8 w-full text-center">
                  <span className="text-sm font-medium capitalize text-gray-600">
                    {dateSeparator(message.createdAt)}
                  </span>
                </div>
              )}
              <div className={cn('my-3 flex flex-row px-8', messageSentByYou && 'justify-end')}>
                <Message
                  sb={sb}
                  chatType={currentlyJoinedChannel?.customType as ChatTypes}
                  message={message}
                  isDepartmentAdmin={
                    currentlyJoinedChannel?.customType === ChatTypes.DEPARTMENT
                      ? messageSentByYou
                        ? false
                        : currentlyJoinedChannel?.customType === ChatTypes.DEPARTMENT && isAdmin
                          ? false
                          : true
                      : false
                  }
                  readMessages={readMessages}
                  handleDeleteMessage={handleDeleteMessage}
                  updateMessage={updateMessage}
                  messageSentByYou={messageSentByYou}
                />
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
);

MessagesList.displayName = 'MessagesList';

export { MessagesList };
