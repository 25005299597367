'use client';
import Folder from './../../../public/icons/folder-2.svg';
import Calendar from './../../../public/icons/calendar.svg';
import CalendarYellow from './../../../public/icons/calendar-yellow.svg';
import DocumentRose from './../../../public/icons/document-rose.svg';
import UserSquareBlue from './../../../public/icons/user-square-blue.svg';
import UserSquareCyan from './../../../public/icons/user-square-cyan.svg';
import UserSquareRose from './../../../public/icons/user-square-rose.svg';
import { useTranslations } from 'next-intl';
import { StatisticBadge } from '@/components/common/statistic-badge';
import { NavItem } from './components/nav-item';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { useDashboardsQuery } from '@/hooks/fetchers/queries/dashboards/useDashboardsQuery';
import { useSession } from 'next-auth/react';

export function DashboardTherapistView() {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const { data: dashboardData } = useDashboardsQuery();
  const { data: session } = useSession();

  return (
    <main className="pl-4 pr-6">
      {session?.user && <h1 className="mt-2 py-4 text-2xl font-bold">{t('Pages.Dashboard.title', { name: session.user.firstName })}</h1>}
      <div className="mb-6 flex flex-row space-x-4">
        <StatisticBadge
          className="min-w-80"
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Folder}
          label={t('Pages.Dashboard.pastDueDocs')}
          value={dashboardData?.pastDueDocumentsCount ?? 0}
        />
        <StatisticBadge
          className="min-w-80"
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Calendar}
          label={t('Pages.Dashboard.workedVsHoursPlanned')}
          value={`${dashboardData?.hoursProvided ?? 0}/${dashboardData?.hoursPlanned ?? 0}`}
        />
      </div>

      <div className="flex flex-row space-x-4">
        {checkPermissions([PermissionsTypes.AVAILABLE_CLIENTS_LIST]) && (
          <NavItem
            path="/dashboard/available-clients"
            className="bg-blue-50"
            svgPath={UserSquareBlue}
            label={t('Pages.Dashboard.availableClients')}
          />
        )}
        {checkPermissions([PermissionsTypes.MY_CLIENTS_LIST]) && (
          <NavItem
            path="/dashboard/my-clients"
            className="bg-rose-100"
            svgPath={UserSquareRose}
            label={t('Pages.Dashboard.myClients')}
          />
        )}
        {checkPermissions([PermissionsTypes.VIEW_SIGNATURES]) && (
          <NavItem
            path="/dashboard/signatures"
            className="bg-cyan-200"
            svgPath={UserSquareCyan}
            label={t('Pages.Dashboard.guardianSignatures')}
          />
        )}
        {checkPermissions([PermissionsTypes.MY_SCHEDULE]) && (
          <NavItem
            path="/dashboard/my-schedule"
            className="bg-yellow-100"
            svgPath={CalendarYellow}
            label={t('Pages.Dashboard.mySchedule')}
          />
        )}
        {checkPermissions([PermissionsTypes.VIEW_MY_DOCUMENTS]) && (
          <NavItem
            path="/dashboard/my-documents"
            className="bg-red-100"
            svgPath={DocumentRose}
            label={t('Pages.Dashboard.myDocuments')}
          />
        )}
      </div>

     
    </main>
  );
}
