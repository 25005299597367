'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/components/ui';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { ACTIVITY_ACTIONS, ACTIVITY_ENTITIES } from '@/common/constants/common';
import { DateRange, DateRangePicker } from '@/components/form/date-range-picker/date-range-picker';
import { useEffect, useState } from 'react';
import dayjs from '@/lib/dayjsConfig';

export interface FilterAuditLogParams {
  user?: TagItem[];
  action?: string;
  entity?: string;
  dateRange?: TagItem[];
}

interface Props {
  value?: FilterAuditLogParams;
  onApply: (value: FilterAuditLogParams) => void;
  onCancel: () => void;
}

export function FilterAuditLogForm({ value, onApply, onCancel }: Props) {
  const t = useTranslations();
  const [dateRange, setDateRange] = useState<TagItem[]>([]);

  const form = useForm<FilterAuditLogParams>({
    defaultValues: {
      user: value ? value.user : [],
      action: value ? value.action : '',
      entity: value ? value.entity : '',
    },
  });

  const handleChooseDateRange = (date: DateRange): void => {
    if (date.from && date.to) {
      setDateRange([
        {
          label: `${dayjs(date.from).format('MM.DD.YYYY')} - ${dayjs(date.to).format('MM.DD.YYYY')}`,
          value: { id: 'date_range', ...date },
        },
      ]);
    } else {
      setDateRange([]);
    }
  };

  const onSubmit: SubmitHandler<FilterAuditLogParams> = (data: FilterAuditLogParams): void => {
    onApply({
      ...data,
      dateRange,
    });
  };

  useEffect(() => {
    if (value && value.dateRange && value.dateRange[0]) {
      setDateRange([
        {
          label: value.dateRange[0].label,
          value: value.dateRange[0].value,
        },
      ]);
    } else {
      setDateRange([]);
    }
  }, [value]);

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="pl-1 pr-4">
          <div className="relative mb-2">
            <FormItem>
              <FormLabel>{t('Forms.dateRange.label')}</FormLabel>
              <DateRangePicker
                onUpdate={(values) => handleChooseDateRange(values.range)}
                initialDateFrom={
                  value?.dateRange && value.dateRange[0]
                    ? value.dateRange[0].value.from
                    : dateRange[0]
                      ? dateRange[0].value.from
                      : null
                }
                initialDateTo={
                  value?.dateRange && value.dateRange[0]
                    ? value.dateRange[0].value.to
                    : dateRange[0]
                      ? dateRange[0].value.to
                      : null
                }
                align="end"
                locale="en-GB"
              />
            </FormItem>
          </div>
          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="action"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.actionType.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.actionType.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {ACTIVITY_ACTIONS.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="entity"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.entityType.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.entityType.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {ACTIVITY_ENTITIES.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
        </div>

        <footer className="flex flex-row items-center justify-end space-x-2">
          <Button onClick={onCancel} variant="outline" type="button" size="lg" className="mt-8">
            {t('Buttons.cancel')}
          </Button>
          <Button type="submit" size="lg" className="mt-8">
            {t('Buttons.apply')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
