import { z } from 'zod';

export const forgotPasswordValidationSchema = z.object({
  email: z
    .string({ message: 'Forms.required' })
    .min(1, { message: 'Forms.required' })
    .max(100, { message: 'Forms.email.max' })
    .email('Forms.invalid'),
});

export type ForgotPasswordValidationSchema = z.infer<typeof forgotPasswordValidationSchema>;