import { ALLOWED_DOMAIN } from '@/common/constants';
import { z } from 'zod';

export const changeEmailValidationSchema = z.object({
  currentEmail: z.string().min(1, { message: 'Forms.required' }).email('Forms.invalid'),
  newEmail: z
    .string({ message: 'Forms.email.required' })
    .min(1, { message: 'Forms.email.required' })
    .max(100, { message: 'Forms.email.max' })
    .email('Forms.invalid')
    .refine((value) => value.endsWith(ALLOWED_DOMAIN), {
      message: 'Forms.email.wrongDomain',
    }),
  password: z.string(),
});

export type ChangeEmailValidationSchema = z.infer<typeof changeEmailValidationSchema>;
