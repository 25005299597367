import dayjs from '@/lib/dayjsConfig';

export const protectFromXSS = (text: string) => {
  return text
    .replace(/\&/g, '&amp;')
    .replace(/\</g, '&lt;')
    .replace(/\>/g, '&gt;')
    .replace(/\"/g, '&quot;')
    .replace(/\'/g, '&apos;');
};

export const timestampToTime = (timestamp: number) => {
  const now = new Date().getTime();
  const nowDate = dayjs.unix(now.toString().length === 13 ? now / 1000 : now).format('MM/DD/YY');
  let date = dayjs.unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp).format('MM/DD/YY');
  if (date === 'Invalid date') {
    date = '';
  }
  return nowDate === date
    ? dayjs.unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp).format('h:mma')
    : date;
};

export const handleEnterPress = (event: any, callback: any) => {
  if (event.key === 'Enter') {
    callback();
  }
};
