import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation } from '@tanstack/react-query';

interface Request {
  otp: string;
  id: string;
  password: string;
  otpType: string;
}

export type Options = Omit<
  UseMutationOptions<string, DefaultError, Request>,
  'mutationKey' | 'mutationFn'
>;

export const useSetupPassMutation = (options?: Options) => {
  const _mutation = async (value: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`auth/reset-password`, value);
    return data;
  };

  return useMutation({
    mutationKey: ['auth', 'reset-password'],
    mutationFn: _mutation,
    ...options,
  });
};
