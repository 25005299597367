import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui';
import { shortNumber } from '@/lib/utils';
import { timestampToTime } from '../utils/messageUtils';
import { useEffect, useState } from 'react';
import { SendbirdChatWith } from '@sendbird/chat';
import {
  GroupChannelModule,
  GroupChannel,
} from '@sendbird/chat/groupChannel';


interface ChannelItemProps {
  sb: SendbirdChatWith<GroupChannelModule[]>;
  channel: GroupChannel | any;
}

export function ChannelItem({ sb, channel }: ChannelItemProps) {
  const [chatView, setChatView] = useState<{title: string; logoChar: string}>({
    title: '',
    logoChar: ''
  });

  useEffect(() => {
    if (channel.name === '' && channel.memberCount === 2) {
      const interlocutor = channel.members.find((member: any) => member.userId !== sb.currentUser?.userId);
      if (interlocutor) {
        setChatView({
          title: interlocutor.nickname,
          logoChar: interlocutor.nickname.charAt(0),
        });
      }
    } else {
      setChatView({
        title: channel.name,
        logoChar: channel.name.charAt(0),
      });
    }
  }, [])

  return (
    <div className="border-grey-lighter flex min-h-20 items-start border-b px-3">
      <Avatar className="mt-4 h-8 w-8 rounded-full">
        <AvatarImage src={channel.coverUrl} alt="Profile picture" />
        <AvatarFallback>{chatView.logoChar}</AvatarFallback>
      </Avatar>
      <div className="ml-4 flex-1 cursor-pointer py-4">
        <div className="items-bottom flex justify-between">
          <p className="text-grey-700 text-sm font-medium">
            {chatView.title}
          </p>
          <p className="text-grey-600 whitespace-nowrap text-xs">
            {timestampToTime(channel.lastMessage ? channel.lastMessage.createdAt : channel.createdAt)}
          </p>
        </div>
        <div className="items-bottom flex justify-between">
          <p className="text-grey-600 line-clamp-2 break-all text-xs">
            {channel.lastMessage?.isFileMessage() ? channel.lastMessage?.name : channel.lastMessage?.message}
          </p>
          {channel.unreadMessageCount > 0 && (
            <p className="flex h-5 w-5 min-w-5 items-center justify-center rounded-full bg-yellow-500 text-xs text-white">
              {shortNumber(channel.unreadMessageCount)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
