import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Guardian } from '@/views/all-guardians/interfaces/guardians.interface';

type Response = {
  id: string;
  firstName: string;
  lastName: string;
};

interface Params {
  email?: string;
  phoneNumber?: string;
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Response>, 'queryKey' | 'queryFn'>;

export const useGuardianExistQuery = (params: Params, options?: Options) => {
  const queryParams = {
    email: params.email,
    phoneNumber: params.phoneNumber,
  };

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('guardians/exist', {
      params: queryParams,
    });
    return data;
  };

  return useQuery({
    queryKey: ['guardians', 'exist', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
