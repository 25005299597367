'use client';
import { useTranslations } from 'next-intl';
import { MyClientsTable } from './table/my-clients-table';

export function MyClientsView() {
  const t = useTranslations();

  return (
    <main className='pl-4 pr-6'>
      <h1 className='font-bold text-2xl py-4 mt-2'>{t('Pages.MyClients.title')}</h1>
      <MyClientsTable />
    </main>
  );
}
