'use client';
import { useTranslations } from 'next-intl';
import { SetupNewPasswordForm } from './setup-new-password-form';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export function SetupNewPasswordView({ otp, userId, otpType, isMobile }: { otp: string; userId: string; otpType: string; isMobile: boolean }) {
  const t = useTranslations();
  const [isMobileView, setIsMobileView] = useState(true);
  const searchParams = useSearchParams();
  const router = useRouter();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any)['opera'];
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    setIsMobileView(isMobile);
    if (isMobile) {
      router.replace(`/mobile-app-only?${searchParams.toString()}`);
    }
  }, [router]);

  if (isMobileView) {
    return <></>
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-0 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
          {t('Pages.Auth.setNewPassword')}
        </h2>
        <h5 className="mt-4 text-center text-gray-400">{t('Pages.Auth.setNewPasswordDescription')}</h5>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
        <SetupNewPasswordForm otp={otp} userId={userId} otpType={otpType} />
      </div>
    </div>
  );
}
