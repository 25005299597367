import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ProfileChangeEmailForm } from '@/views/my-profile/interfaces/profile.interface';
import { DefaultError, UseMutationOptions, useMutation } from '@tanstack/react-query';

export type Options = Omit<
  UseMutationOptions<string, DefaultError, ProfileChangeEmailForm>,
  'mutationKey' | 'mutationFn'
>;

export const useUpdateMyEmailMutation = (options?: Options) => {
  const _mutation = async (value: ProfileChangeEmailForm): Promise<string> => {
    const { data } = await axiosInterceptorInstance.patch(`me/change-email`, value);
    return data;
  };

  return useMutation({
    mutationKey: ['me', 'change-email'],
    mutationFn: _mutation,
    ...options,
  });
};
