'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/form';
import { useTranslations } from 'next-intl';
import { changePasswordValidationSchema, ChangePasswordValidationSchema } from './change-pass-validation-schema';
import lodash from 'lodash';
import { useCallback, useEffect } from 'react';
import { ProfileChangePasswordForm } from '../interfaces/profile.interface';

interface ChangePasswordFormProps {
  onChanges: (data: ProfileChangePasswordForm) => void;
}

export function ChangePasswordForm({ onChanges }: ChangePasswordFormProps) {
  const t = useTranslations();

  const form = useForm<ChangePasswordValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(changePasswordValidationSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const password = form.watch('newPassword');

  const onChangeHandler = useCallback(
    lodash.debounce(() => onChanges(form.getValues()), 300),
    []
  );

  useEffect(() => {
    if (password && form.getValues('confirmPassword').length > 0) {
      form.trigger('confirmPassword');
    }
  }, [password, form.trigger]);


  return (
    <Form {...form}>
      <h2 className="mt-6 text-sm font-semibold text-gray-950">{t('Pages.MyProfile.changePassword')}</h2>
      <p className="mb-6 mt-3 text-base font-light text-gray-400">{t('Pages.MyProfile.passwordRule')}</p>
      <form onChange={onChangeHandler} noValidate className="grid grid-cols-1 gap-8">
        <div className="relative max-w-72">
          <FormField
            control={form.control}
            name="currentPassword"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel className="block first-letter:uppercase" isRequired>
                  {t('Forms.currentPassword.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="password"
                    autoComplete="current-password"
                    placeholder={t('Forms.currentPassword.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative max-w-72">
          <FormField
            control={form.control}
            name="newPassword"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel className="block first-letter:uppercase" isRequired>
                  {t('Forms.password.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="password"
                    autoComplete="new-password"
                    placeholder={t('Forms.password.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative max-w-72">
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel className="block first-letter:uppercase" isRequired>
                  {t('Forms.confirmPassword.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="password"
                    autoComplete="confirm-password"
                    placeholder={t('Forms.confirmPassword.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
}
