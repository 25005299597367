import { z } from 'zod';

export const logInValidationSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Forms.required' })
    .email('Forms.email.invalid'),
  password: z.string({ required_error: 'Forms.required' }).min(1, { message: 'Forms.required' }),
});

export type LogInValidationSchema = z.infer<typeof logInValidationSchema>;
