import { Colors } from '@/common/enums';
import { Chips, LabelValueItem, ScrollArea } from '@/components/common';
import dayjs from '@/lib/dayjsConfig';
import { AuditLog, New, Old, User } from './audit-log.interface';
import { ACTIVITY_ACTION_TYPES, ACTIVITY_ENTITY_TYPES } from '@/common/constants/common';
import { useTranslations } from 'next-intl';
import { ArrowDown } from 'lucide-react';
import { compileFullName } from '@/lib/utils';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';

export interface AuditLogPreviewProps {
  action: ACTIVITY_ACTION_TYPES;
  entity: ACTIVITY_ENTITY_TYPES;
  changes: {
    label: string;
    previous: Partial<Old>;
    current: Partial<New>;
  }[];
  onPreviewUser?: (user: User) => void;
  onPreviewEntity?: (log: AuditLog) => void;
  updatedAt: string;
  log: AuditLog;
  user?: User;
}

export function AuditLogPreview({
  log,
  action,
  entity,
  changes,
  updatedAt,
  user,
  onPreviewUser,
  onPreviewEntity,
}: AuditLogPreviewProps) {
  const t = useTranslations();

  const clickableEntity = [
    ACTIVITY_ENTITY_TYPES.CLIENTS,
    ACTIVITY_ENTITY_TYPES.CLIENT_GUARDIANS,
    ACTIVITY_ENTITY_TYPES.DOCUMENTS,
    ACTIVITY_ENTITY_TYPES.SERVICES,
    ACTIVITY_ENTITY_TYPES.SERVICE_SHIFTS,
    ACTIVITY_ENTITY_TYPES.SHIFTS,
    ACTIVITY_ENTITY_TYPES.SERVICE_SHIFTS,
    ACTIVITY_ENTITY_TYPES.THERAPIST_RATES,
    ACTIVITY_ENTITY_TYPES.THERAPIST_SUPERVISORS,
    ACTIVITY_ENTITY_TYPES.THERAPIST_WORK_LOGS,
  ];

  let actionPerformed;
  switch (action) {
    case ACTIVITY_ACTION_TYPES.DEACTIVATE:
      actionPerformed = `${t('Pages.AuditLog.deactivate')} ${entity}`;
      break;
    case ACTIVITY_ACTION_TYPES.SOFT_DELETE:
      actionPerformed = `${t('Pages.AuditLog.softDeleted')} ${entity}`;
      break;
    case ACTIVITY_ACTION_TYPES.RESTORE:
      actionPerformed = `${t('Pages.AuditLog.restored')} ${entity}`;
      break;
    case ACTIVITY_ACTION_TYPES.UPDATE:
      actionPerformed = `${t('Pages.AuditLog.updated')} ${entity}`;
      break;
    case ACTIVITY_ACTION_TYPES.CREATE:
      actionPerformed = `${t('Pages.AuditLog.created')} ${entity}`;
      break;
    default:
      actionPerformed = `---`;
      break;
  }

  return (
    <div className="w-full">
      <div className="border-r border-slate-200">
        <header className="flex flex-row items-center justify-between px-4 py-4">
          <h1 className="text-2xl font-bold">{t('Pages.AuditLog.previewTitle')}</h1>
        </header>
        <ScrollArea className="h-[calc(100vh-128px)] overflow-auto">
          <div className="space-y-4 px-4">
            {user && (
              <LabelValueItem className="max-w-[672px]" label={t('Forms.user.label')} withoutBorder>
                <Chips
                  color={USER_ROLES_COLORS[user.role]}
                  title={compileFullName(user)}
                  onClickAction={(e) => {
                    onPreviewUser?.(user as User);
                    e.stopPropagation();
                  }}
                />
              </LabelValueItem>
            )}

            <LabelValueItem
              className="max-w-[672px]"
              label={t('Forms.actionPerformed.label')}
              value={actionPerformed}
            />

            <div className="grid grid-cols-form-cols-2 gap-8">
              <LabelValueItem label={t('Forms.date.label')} value={dayjs(updatedAt).format('MM.DD.YYYY')} />
              <LabelValueItem label={t('Forms.time.label')} value={dayjs(updatedAt).utcOffset(dayjs().format('Z')).format('h:mm A')} />
            </div>
            <LabelValueItem className="max-w-[672px]" label={t('Forms.entityTypeChanged.label')} value={entity} />

            {clickableEntity.includes(entity) && (
              <LabelValueItem className="max-w-[672px]" label={t('Forms.entityWasChanged.label')} withoutBorder>
                <Chips
                  color={Colors.green}
                  title={entity}
                  onClickAction={(e) => {
                    onPreviewEntity?.(log);
                    e.stopPropagation();
                  }}
                />
              </LabelValueItem>
            )}

            {changes.length > 0 && (
              <div className={'flex w-full flex-col'}>
                <h4 className="mb-2 ml-1 mt-1 select-none text-sm font-medium text-gray-700">
                  {t('Pages.AuditLog.descriptionOfChange')}
                </h4>

                {changes.map((item, index) => (
                  <div key={index} className="mb-4 flex flex-col gap-2">
                    <div className="w-full max-w-[672px] rounded-sm bg-gray-100 p-4">
                      <LabelValueItem withoutBorder label={item.label} value={item.previous as string} />
                    </div>
                    <ArrowDown className="h-4 w-4 text-gray-800" />
                    <div className="w-full max-w-[672px] rounded-sm bg-blue-50 p-4">
                      <LabelValueItem withoutBorder label={item.label} value={item.current as string} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
