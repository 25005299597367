import { ColumnDef, Row } from '@tanstack/react-table';
import { Timesheet } from '../interfaces/timesheet.interface';
import { Chips } from '@/components/common';
import { Colors } from '@/common/enums';
import dayjs from '@/lib/dayjsConfig';
import { TIMESHEET_STATUS_COLORS } from '@/common/constants/chips-colors';
import { capitalizeFirstLetter, minutesToTime } from '@/lib/utils';

type Params = {
  t: any;
};

export const getTimesheetsColumns = ({t}: Params): ColumnDef<Timesheet>[] => {
  const columns: ColumnDef<Timesheet>[] = [
    {
      id: 'Period',
      enablePinning: false,
      header: () => <span>{t('Tables.period')}</span>,
      cell: ({ row }) => {
        if (row.original.periodTo && row.original.periodFrom) {
          return <span>{`${dayjs.utc(row.original.periodFrom).format('MMM')} ${dayjs.utc(row.original.periodFrom).format('DD')} - ${dayjs.utc(row.original.periodTo).format('DD')}`}</span>;
        } else {
          return <span></span>;
        }
      },
      size: 140,
    },
    {
      id: 'Amount of Shifts',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfShifts')}</span>,
      cell: ({ row }) => {
        if (row.original.report) {
          return <span>{row.original.report.shiftsCount}</span>;
        } else {
          return <span></span>;
        }
      },
      size: 140,
    },
    {
      id: 'Amount of Hours Worked',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfHoursWorked')}</span>,
      cell: ({ row }) => {
        if (row.original.report) {
          return <span>{row.original.report.totalDuration ? minutesToTime(row.original.report.totalDuration) : '---'}</span>;
        } else {
          return <span></span>;
        }
      },
      size: 140,
    },
    {
      id: 'Status',
      enablePinning: false,
      header: () => <span>{t('Tables.status')}</span>,
      cell: ({ row }) => {
        if (row.original.status) {
          return <Chips color={(TIMESHEET_STATUS_COLORS as any)[row.original.status]} title={capitalizeFirstLetter(row.original.status)} />;
        } else {
          return <></>;
        }
      },
      size: 140,
    },
  ];

  return columns;
};
