import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { Admin } from '@/views/admin-users-list/interfaces/admins.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

type Response = {
  data: Admin[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  search?: string;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Response>, 'queryKey' | 'queryFn'>;

export const useAdminsQuery = (params: Params, options?: Options) => {
  const { skip, take, search, sortField } = params;

  const queryParams = {
    skip, 
    take, 
    search, 
    sortField, 
    sortOrder: params.sortField ? params.sortOrder : null,
  };
  const cleanedQueryParams = _.omitBy(queryParams, value => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('admins', {
      params: cleanedQueryParams,
    });
    return data;
  };

  return useQuery({
    queryKey: ['admins', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
