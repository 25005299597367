import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

type Request = {
  id: string;
  value: {
    name: string;
    userList: string[];
  };
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateDepartmentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id, value }: Request): Promise<string> => {
    const body = _.omitBy(value, v => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.put(`departments/${id}`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['departments'],
    mutationFn: _mutation,
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['departments'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    ...options,
  });
};
