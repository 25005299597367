import { Button } from '@/components/ui';
import { formatFileSize } from '@/lib/utils';
import { Download, FileText } from 'lucide-react';

interface MessageFileContainerProps {
  message: any;
  onDownload: () => void;
}

export function MessageFileContainer({ message, onDownload }: MessageFileContainerProps) {
  return (
    <div className="leading-1.5 flex w-full min-w-[320px] flex-col rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 p-4">
      <div className="flex items-start rounded-xl bg-gray-50 p-2">
        <div className="me-2">
          <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900">
            <FileText />
            {message.name}
          </span>
          <span className="flex gap-2 text-xs font-normal text-gray-500">
            {formatFileSize(message.size, 2)}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="self-center"
              width="3"
              height="4"
              viewBox="0 0 3 4"
              fill="none"
            >
              <circle cx="1.5" cy="2" r="1.5" fill="#6B7280" />
            </svg>
            {message.type}
          </span>
        </div>
        <div className="inline-flex items-center self-center">
          <Button
            variant="ghost"
            className="inline-flex items-center self-center rounded-lg bg-gray-50 p-2 text-center text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-50"
            size="icon"
          >
            <Download onClick={onDownload} />
          </Button>
        </div>
      </div>
    </div>
  );
}
