import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Request = {
  id: string;
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useApproveTimesheetMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id }: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`timesheets/${id}/approve`);
    return data;
  };

  return useMutation({
    mutationKey: ['timesheets', 'approve'],
    onSettled: (data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['timesheets'] });
        queryClient.invalidateQueries({ queryKey: ['timesheets'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    mutationFn: _mutation,
    ...options,
  });
};
