import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { Department } from '@/views/chats/interfaces/department.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

type Response = {
  data: Department[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  search?: string;
}

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useDepartmentsQuery = <T = Response>(params: Params, options?: Options<T>) => {

  const queryParams = {
    skip: params.skip,
    take: params.take,
    search: params.search,
  };

  const cleanedQueryParams = _.omitBy(queryParams, value => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('departments', {
      params: cleanedQueryParams
    });
    return data;
  };

  return useQuery({
    queryKey: ['departments', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
