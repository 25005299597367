import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { AvailableClient } from '../interfaces/available-clients.interface';
import { Chips, CommentsList, LabelValueItem, ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import {
  compileFullName,
  getColorForServiceRole,
  openAddressInGoogleMaps,
  transformApiDateToViewDate,
} from '@/lib/utils';
import { useReserveMutation } from '@/hooks/fetchers/mutations/services/useReserveMutation';
import { Tabs, TabsContent, TabsTrigger } from '@/components/ui/tabs';
import { TabsList } from '@radix-ui/react-tabs';
import { Dot } from 'lucide-react';
import { useCommentsQuery } from '@/hooks/fetchers/queries/useCommentsQuery';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';

export enum AvailableClientPreviewTabs {
  'INFO' = 'info',
  'COMMENTS' = 'comments',
}

export type PreviewAvailableClientDialogRef = {
  open: (client: AvailableClient) => void;
};

interface Props {
  onRefresh: () => void;
}

const PreviewAvailableClientDialog = forwardRef<PreviewAvailableClientDialogRef, Props>(({ onRefresh }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const [client, setClient] = useState<AvailableClient | null>(null);
  const { data: comments } = useCommentsQuery(client?.id, { refetchOnWindowFocus: isOpen });

  useImperativeHandle(ref, () => ({
    open: (client) => {
      setIsOpen(true);
      setClient(client);
    },
  }));

  const { mutate: reserve, isPending } = useReserveMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.clientReserved'),
      });
      onRefresh();
      setIsOpen(false);
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
        <DialogHeader>
          <DialogTitle>{t('Pages.AvailableClients.clientDetails')}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <Tabs defaultValue={AvailableClientPreviewTabs.INFO}>
          <TabsList className="flex w-96 flex-row space-x-4">
            <TabsTrigger className="w-full" value={AvailableClientPreviewTabs.INFO}>
              {t('Buttons.profileInfo')}
            </TabsTrigger>
            <TabsTrigger className="relative w-full" value={AvailableClientPreviewTabs.COMMENTS}>
              {comments && comments.length > 0 ? (
                <Dot className="absolute right-[-4px] top-[-4px] h-8 w-8 text-destructive" />
              ) : null}
              {t('Buttons.comments')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value={AvailableClientPreviewTabs.INFO} className="pt-4">
            <ScrollArea className="h-[calc(100vh-364px)] overflow-auto">
              <div className="mb-6 flex w-full max-w-[676px] flex-col space-y-6">
                <LabelValueItem label={t('Forms.title.label')} value={client?.title} />
              </div>
              <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
                <LabelValueItem label={t('Forms.client.label')} value={compileFullName(client?.client)} />
                <LabelValueItem
                  label={t('Forms.authorizedTo.label')}
                  value={transformApiDateToViewDate(client?.authorizedTo)}
                />
                <LabelValueItem label={t('Forms.serviceRole.label')} withoutBorder>
                  <Chips
                    color={getColorForServiceRole(client?.serviceRole.title, client?.serviceRole.speciality.code)}
                    title={client?.serviceRole.title}
                  />
                </LabelValueItem>
                <LabelValueItem label={t('Forms.frequency.label')} value={client?.frequency} />
              </div>
              <div className="mt-6 flex w-full max-w-[676px] flex-col space-y-6">
                <LabelValueItem
                  label={t('Forms.speakingLanguage.label')}
                  value={
                    client && client.languages && client.languages.length > 0 ? client.languages.join(', ') : undefined
                  }
                />

                <LabelValueItem label={t('Forms.guardian.label')}>
                  <div className="space-x-2">
                    {client?.client &&
                      client?.client.guardians &&
                      client?.client.guardians.map((guardian) => (
                        <Chips
                          key={guardian.id}
                          color={USER_ROLES_COLORS[RoleTypes.Guardian]}
                          title={compileFullName(guardian)}
                        />
                      ))}
                  </div>
                </LabelValueItem>

                <LabelValueItem
                  label={t('Forms.address.label')}
                  value={client?.address}
                  onClickByValue={() => openAddressInGoogleMaps(client?.address)}
                />
              </div>
            </ScrollArea>
            {!client?.client.deactivatedAt && (
              <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
                <Button
                  disabled={isPending}
                  type="button"
                  size="lg"
                  className="mt-5"
                  onClick={() => reserve({ serviceId: client?.id as string })}
                >
                  {t('Buttons.reserve')}
                </Button>
              </DialogFooter>
            )}
          </TabsContent>

          <TabsContent value={AvailableClientPreviewTabs.COMMENTS}>
            <div className="h-[calc(100vh-236px)]">
              {client && <CommentsList scrollAreaClassName="h-[calc(100vh-308px)]" serviceId={client.id} />}
            </div>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
});

PreviewAvailableClientDialog.displayName = 'PreviewAvailableClientDialog';

export { PreviewAvailableClientDialog };
