import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { MyClient } from '../interfaces/my-clients.interface';
import { MyClientPreviewTabs } from '../enums/my-client-preview-tabs';
import { MyClientProfileTab } from './my-client-profile-tab';
import { AllShiftsTable } from '@/views/all-shifts/table/all-shifts-table';
import { CommentsList } from '@/components/common';
import { useMyClientQuery } from '@/hooks/fetchers/queries/services/useMyClientQuery';
import { AllDocumentsTable } from '@/views/all-documents/table/all-documents-table';
import { useCommentsQuery } from '@/hooks/fetchers/queries/useCommentsQuery';
import { Dot } from 'lucide-react';

interface Props {
  refreshData?: () => void;
}

export type PreviewMyClientDialogRef = {
  open: (client: MyClient) => void;
  openById: (id: string) => void;
};

const PreviewMyClientDialog = forwardRef<PreviewMyClientDialogRef, Props>(({ refreshData }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const [clientProfile, setClientProfile] = useState<MyClient | null>(null);
  const [clientId, setClientId] = useState<string>();

  const { data: comments } = useCommentsQuery(clientId, { refetchOnWindowFocus: isOpen });
  const { refetch: refetchClient, data: clientProfileById } = useMyClientQuery(clientId as string, {
    enabled: false,
    refetchOnWindowFocus: isOpen,
  });

  useImperativeHandle(ref, () => ({
    open: (client) => {
      setIsOpen(true);
      setClientProfile(client);
    },
    openById: (id) => {
      setIsOpen(true);
      setClientId(id);
    },
  }));

  const handleDeclineService = () => {
    setIsOpen(false);
    refreshData?.();
  };

  useEffect(() => {
    refetchClient();
  }, [clientId]);

  useEffect(() => {
    if (!isOpen && clientId) {
      setClientId(undefined);
    }
  }, [isOpen]);

  const client = clientProfile ? clientProfile : clientProfileById;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
        <DialogHeader>
          <DialogTitle>{t('Pages.AllClients.clientProfile')}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <Tabs defaultValue={MyClientPreviewTabs.INFO}>
          <TabsList className="w-full max-w-[700px]">
            <TabsTrigger className="w-full" value={MyClientPreviewTabs.INFO}>
              {t('Buttons.profileInfo')}
            </TabsTrigger>
            <TabsTrigger className="w-full" value={MyClientPreviewTabs.DOCUMENTS}>
              {t('Buttons.documents')}
            </TabsTrigger>

            <TabsTrigger className="w-full" value={MyClientPreviewTabs.SHIFTS}>
              {t('Buttons.shifts')}
            </TabsTrigger>
            <TabsTrigger className="relative w-full" value={MyClientPreviewTabs.COMMENTS}>
              {comments && comments.length > 0 ? (
                <Dot className="absolute right-[-4px] top-[-4px] h-8 w-8 text-destructive" />
              ) : null}
              {t('Buttons.comments')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value={MyClientPreviewTabs.INFO} className="pt-4">
            <div className="h-[calc(100vh-244px)]">
              {client && (
                <MyClientProfileTab
                  onRefresh={refetchClient}
                  onClose={() => setIsOpen(false)}
                  onDecline={handleDeclineService}
                  client={client}
                />
              )}
            </div>
          </TabsContent>

          <TabsContent value={MyClientPreviewTabs.DOCUMENTS}>
            <div className="h-[calc(100vh-228px)]">
              {client && (
                <AllDocumentsTable
                  tableClassName="h-[calc(100vh-376px)]"
                  serviceId={client.id}
                  hideCreate={false}
                  hideTableSettings
                />
              )}
            </div>
          </TabsContent>

          <TabsContent value={MyClientPreviewTabs.SHIFTS}>
            <div className="h-[calc(100vh-228px)]">
              {client && (
                <AllShiftsTable
                  tableClassName="h-[calc(100vh-368px)]"
                  serviceId={client.id}
                  hideCreate={false}
                  hideTableSettings
                />
              )}
            </div>
          </TabsContent>
          <TabsContent value={MyClientPreviewTabs.COMMENTS}>
            <div className="h-[calc(100vh-236px)]">
              {client && <CommentsList scrollAreaClassName="h-[calc(100vh-308px)]" serviceId={client.id} />}
            </div>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
});

PreviewMyClientDialog.displayName = 'PreviewMyClientDialog';

export { PreviewMyClientDialog };
