import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';



export type Options = Omit<UseMutationOptions<any, DefaultError, string>, 'mutationKey' | 'mutationFn'>;

export const useRequestExtensionMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (id: string): Promise<any> => {
    const { data } = await axiosInterceptorInstance.patch(`services/me/clients/${id}/request-extention`);
    return data;
  };

  return useMutation({
    mutationKey: ['services', 'me', 'clients'],
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['services'] });
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    mutationFn: _mutation,
    ...options,
  });
};
