'use client';
import { useTranslations } from 'next-intl';
import { SignaturesTable } from './table/signatures-table';

export function SignaturesView() {
  const t = useTranslations();

  return (
    <main className='pl-4 pr-6'>
      <h1 className='font-bold text-2xl py-4 mt-2'>{t('Pages.Signatures.title')}</h1>
      <SignaturesTable />
    </main>
  );
}
