'use client';
import { useState, useEffect, createRef, useCallback, useRef } from 'react';
import {
  GroupChannelModule,
  GroupChannelFilter,
  GroupChannelHandler,
  GroupChannelListOrder,
  GroupChannel,
  MessageFilter,
  MessageCollectionInitPolicy,
} from '@sendbird/chat/groupChannel';
import { SendbirdChatWith } from '@sendbird/chat';
import { BaseMessage, UserMessage, UserMessageCreateParams, FileMessageCreateParams } from '@sendbird/chat/message';
import lodash from 'lodash';

import { ChannelList, ChatContainer, MessageInput } from '.';
import { useSendBirdInstance, useSendBirdStore } from '@/providers';
import { useTranslations } from 'next-intl';
import { LoadingApiState } from './loading-api-state';
import { MessageListRef, MessagesList } from './message/messages-list';

export function ChatsView() {
  const { sb, isLoaded } = useSendBirdInstance();
  const t = useTranslations();
  const channelRef = useRef();
  const [messageInputValue, setMessageInputValue] = useState('');
  const scrollMessagesRef = createRef<MessageListRef>();
  const {
    channels,
    messages,
    readMessages,
    currentlyJoinedChannel,
    messageCollection,
    messageToUpdate,
    setChannels,
    setMessages,
    setCurrentlyJoinedChannel,
    setMessageCollection,
    setMessageToUpdate,
    onMessagesAdded,
    onMessagesUpdated,
    onMessagesDeleted,
    onChannelsAdded,
    onChannelsDeleted,
    onChannelsUpdated,
  } = useSendBirdStore((state) => state);

  useEffect(() => {
    const init = async (sb: SendbirdChatWith<GroupChannelModule[]>) => {
      const [channels, error] = await loadChannels(sb, {
        onChannelsAdded: (context: any, channels: GroupChannel[]) => {
          onChannelsAdded(channels);
        },
        onChannelsDeleted: (context: any, channelsUrl: string[]) => {
          onChannelsDeleted(channelsUrl);
        },
        onChannelsUpdated: (context: any, channels: GroupChannel[]) => {
          onChannelsUpdated(channels);
        },
      });

      if (channels) {
        setChannels(channels);
      }

      if (error) {
        console.log(`%c DEBUG LOG: ERROR LOAD CHANNELS`, 'color: yellow');
        console.error(error);
      }
    };

    if (isLoaded) {
      init(sb as SendbirdChatWith<GroupChannelModule[]>);
    }
  }, [isLoaded]);

  useEffect(() => {
    scrollMessagesRef.current?.scrollToBottom(true);
  }, [messages]);

  useEffect(() => {
    const channelHandler = new GroupChannelHandler({
      onMessageReceived: (channel) => {
        if (currentlyJoinedChannel?.url === channel.url) {    
          markAsRead(channel as GroupChannel);
        }
      },
    });
    sb?.groupChannel.addGroupChannelHandler('UNIQUE_HANDLER_ID', channelHandler);
  }, [currentlyJoinedChannel?.url]);

  const handleMarkAsRead = (channel: GroupChannel) => {
    channel.markAsRead();
  };

  const markAsRead = useCallback(lodash.debounce(handleMarkAsRead, 300), []);

  const fetchCollections = (channel: GroupChannel) => {
    const onCacheResult = (err: any, messages: BaseMessage[] & UserMessage[]) => {
      if (!err) {
        setMessages(messages);
      }
    };

    const onApiResult = (err: any, messages: BaseMessage[] & UserMessage[]) => {
      if (!err) {
        setMessages(messages);
      }
    };
    return loadMessages(
      channel,
      {
        onMessagesAdded: (context: any, channel: GroupChannel, messages: BaseMessage[] & UserMessage[]) => {
          if (currentlyJoinedChannel?.url === channel.url) {            
            markAsRead(channel);
          }
          onMessagesAdded(channel, messages);
        },
        onMessagesUpdated: (context: any, channel: GroupChannel, incomingMessages: BaseMessage[] & UserMessage[]) => {
          if (currentlyJoinedChannel?.url === channel.url) {
            markAsRead(channel);
          }
          onMessagesUpdated(channel, incomingMessages);
        },
        onMessagesDeleted: (context: any, channel: GroupChannel, messageIds: number[]) => {
          onMessagesDeleted(channel, messageIds);
        },
        // onChannelUpdated: (context: any, channel: any) => {},
        // onChannelDeleted: (context: any, channelUrl: any) => {},
        // onHugeGapDetected: () => {},
      },
      onCacheResult,
      onApiResult
    );
  };

  const handleJoinCreatedChannel = async (channel: GroupChannel) => {
    setCurrentlyJoinedChannel(channel);
    const collection = fetchCollections(channel);
    setMessageCollection(collection);
  };

  const handleJoinChannel = async (channelUrl: string) => {
    if (messageCollection && messageCollection.dispose) {
      messageCollection.dispose();
    }

    if (currentlyJoinedChannel?.url === channelUrl) {
      return null;
    }

    const channel = channels.find((channel) => channel.url === channelUrl);

    if (channel) {
      markAsRead(channel);
      setCurrentlyJoinedChannel(channel);
      const collection = fetchCollections(channel);
      setMessageCollection(collection);
    }
  };

  const sendFile = async (files: File[]) => {
    if (files && files.length > 0) {
      const fileMessageParams: FileMessageCreateParams = {
        file: files[0],
      };
      currentlyJoinedChannel
        ?.sendFileMessage(fileMessageParams)
        .onSucceeded(() => {
          setMessageInputValue('');
          setMessageToUpdate(null);
        })
        .onFailed((error: any) => {
          console.log(error);
          console.log('failed');
        });
    }
  };

  const sendMessage = async () => {
    if (messageToUpdate) {
      const userMessageUpdateParams: UserMessageCreateParams = {
        message: messageInputValue,
      };
      const updatedMessage = await currentlyJoinedChannel?.updateUserMessage(
        messageToUpdate.messageId,
        userMessageUpdateParams
      );
      const messageIndex = messages.findIndex((item: any) => item.messageId == messageToUpdate.messageId);
      if (messageIndex !== -1) {
        messages[messageIndex] = updatedMessage as UserMessage;
        setMessageInputValue('');
        setMessageToUpdate(null);
        setMessages(messages);
      }
    } else {
      const userMessageParams: UserMessageCreateParams = {
        message: messageInputValue,
      };
      currentlyJoinedChannel
        ?.sendUserMessage(userMessageParams)
        .onSucceeded(() => {
          markAsRead(currentlyJoinedChannel);
          setMessageInputValue('');
        })
        .onFailed((error: any) => {
          console.log(error);
        });
    }
  };

  const updateMessageHandle = (message: BaseMessage & UserMessage) => {
    setMessageInputValue(message.message);
    setMessageToUpdate(message);
  };

  const deleteMessageHandle = async (message: BaseMessage & UserMessage) => {
    await currentlyJoinedChannel?.deleteMessage(message);
  };

  if (!sb) {
    return <LoadingApiState />;
  }

  return (
    <>
      <div className="h-[calc(100vh-64px)] w-full">
        <div className="flex h-full flex-row">
          <ChannelList
            sb={sb}
            channels={channels}
            handleJoinChannel={handleJoinChannel}
            handleJoinCreatedChannel={handleJoinCreatedChannel}
          />
          <ChatContainer sb={sb} currentlyJoinedChannel={currentlyJoinedChannel} channelRef={channelRef}>
            <MessagesList
              sb={sb}
              messages={messages}
              currentlyJoinedChannel={currentlyJoinedChannel}
              handleDeleteMessage={deleteMessageHandle}
              updateMessage={updateMessageHandle}
              ref={scrollMessagesRef}
              readMessages={readMessages}
            />
            <MessageInput
              value={messageInputValue}
              onChange={(e) => setMessageInputValue(e.currentTarget.value)}
              sendMessage={sendMessage}
              sendFile={sendFile}
            />
          </ChatContainer>
        </div>
      </div>
    </>
  );
}

const loadChannels = async (sb: SendbirdChatWith<GroupChannelModule[]>, channelHandlers: any) => {
  const groupChannelFilter = new GroupChannelFilter();
  groupChannelFilter.includeEmpty = true;

  const collection = sb.groupChannel.createGroupChannelCollection({
    filter: groupChannelFilter,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
  });

  collection.setGroupChannelCollectionHandler(channelHandlers);

  const channels = await collection.loadMore();
  return [channels, null];
};

const loadMessages = (channel: GroupChannel, messageHandlers: any, onCacheResult: any, onApiResult: any) => {
  const messageFilter = new MessageFilter();

  const collection = channel.createMessageCollection({
    filter: messageFilter,
    startingPoint: Date.now(),
    limit: 100,
  });

  collection.setMessageCollectionHandler(messageHandlers);
  collection
    .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
    .onCacheResult(onCacheResult)
    .onApiResult(onApiResult);
  return collection;
};
