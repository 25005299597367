'use client';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { createRef, useRef, useState } from 'react';
import { EventRender } from './event-render';
import { ManageShiftDialog, ManageShiftDialogRef } from '../all-shifts/manage/manage-shift.dialog';
import { useMySchedulesQuery } from '@/hooks/fetchers/queries/shifts/useMySchedulesQuery';
import dayjs from '@/lib/dayjsConfig';
import { PreviewShiftDialog, PreviewShiftDialogRef } from '../all-shifts/preview/preview-shift.dialog';
import { compileFullName } from '@/lib/utils';

export function MyScheduleView() {
  const calendarRef = useRef<FullCalendar>(null);
  const manageShiftDialogRef = createRef<ManageShiftDialogRef>();
  const previewShiftDialogRef = createRef<PreviewShiftDialogRef>();
  const [firstDayOfTheWeekDate, setFirstDayOfTheWeekDate] = useState<string>(
    dayjs().startOf('week').add(1, 'day').toISOString()
  );

  const { data: events, refetch: refetchEvents } = useMySchedulesQuery({
    firstDayOfTheWeekDate,
  });

  const calendarEvents = events?.map((event) => ({
    title: compileFullName(event.client),
    start: event.startAt
      ? dayjs(event.startAt).toISOString()
      : dayjs(event.date)
          .toISOString()
          .replace('T00:00:00.000Z', `T${dayjs(event.startTime, 'h:mm A').format('HH:mm:ss')}.000Z`),
    end: event.endAt
      ? dayjs(event.endAt).toISOString()
      : dayjs(event.date)
          .toISOString()
          .replace('T00:00:00.000Z', `T${dayjs(event.endTime, 'h:mm A').format('HH:mm:ss')}.000Z`),
    extendedProps: event,
  }));

  return (
    <div className="mt-4 h-[calc(100vh-80px)] pl-3 pr-4">
      <FullCalendar
        height={'100%'}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        headerToolbar={{
          left: '',
          center: 'title',
          right: 'prev next today newShift',
        }}
        titleFormat={{ year: 'numeric', month: 'long' }}
        eventMinHeight={74}
        // slotMinTime={'06:00:00'}
        // slotMaxTime={'23:00:00'}
        slotDuration={'00:15:00'}
        slotLaneClassNames={'text-gray-100'}
        slotLabelClassNames={'text-gray-400 text-sm'}
        dayCellClassNames={'bg-blue-50'}
        dayHeaderClassNames={'text-gray-950 text-sm font-light'}
        initialView="timeGridWeek"
        datesSet={(e) => {
          setFirstDayOfTheWeekDate(dayjs(e.start).add(1, 'day').toISOString());
        }}
        weekends
        events={calendarEvents ? [...calendarEvents] : []}
        selectable
        allDaySlot={false}
        eventContent={EventRender}
        eventClick={(e) => {
          previewShiftDialogRef.current?.openById(e.event.extendedProps.id);
        }}
        customButtons={{
          newShift: {
            text: '+ New Shift',
            click: (e) => {
              manageShiftDialogRef.current?.open();
            },
          },
        }}
        locale="en-GB"
        slotLabelInterval={{ hour: 1 }}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short',
        }}
        dayHeaderFormat={{ weekday: 'short', day: 'numeric' }}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false,
        }}
        ref={calendarRef}
      />
      <PreviewShiftDialog onUpdated={refetchEvents} ref={previewShiftDialogRef} />
      <ManageShiftDialog ref={manageShiftDialogRef} onUpdated={refetchEvents} />
    </div>
  );
}
