import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DepartmentDetails } from '@/views/chats/interfaces/department.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, DepartmentDetails | null>, 'queryKey' | 'queryFn'>;

export const useDepartmentQuery = (id?: string, options?: Options) => {
  const _query = async (): Promise<DepartmentDetails | null> => {
    if (id) {
      const { data } = await axiosInterceptorInstance.get(`departments/${id}`);
      return data;
    } else {
      return null;
    }
  };

  return useQuery({
    queryKey: ['departments', id],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
