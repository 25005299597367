'use client';
import { useTranslations } from 'next-intl';
import { AvailableClientsTable } from './table/available-clients-table';

export function AvailableClientsView() {
  const t = useTranslations();

  return (
    <main className='pl-4 pr-6'>
      <h1 className='font-bold text-2xl py-4 mt-2'>{t('Pages.AvailableClients.title')}</h1>
      <AvailableClientsTable />
    </main>
  );
}
