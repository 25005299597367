'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PhoneInput,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { manageAdminValidationSchema, ManageAdminValidationSchema } from './manage-admin-validation-schema';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui';
import { toast, ToastTypeEnums } from '@/components/common';
import { RoleLevels, useRolesQuery } from '@/hooks/fetchers/queries/useRolesQuery';
import { useCreateAdminMutation } from '@/hooks/fetchers/mutations/admins/useCreateAdminMutation';
import { PHONE_COUNTRIES } from '@/common/constants/common';

interface Props {
  onCancel: () => void;
  onUpdate: () => void;
}

export function ManageAdminForm({ onCancel, onUpdate }: Props) {
  const t = useTranslations();

  const {
    data: roles,
    isLoading: isLoadingRolesIds,
  } = useRolesQuery(RoleLevels.ADMIN);

  const { mutate: create, isPending } = useCreateAdminMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: data,
      });
      form.reset();
      onUpdate();
    },
  });

  const form = useForm<ManageAdminValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(manageAdminValidationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      role: '',
    },
  });

  const onSubmit: SubmitHandler<ManageAdminValidationSchema> = async (value: ManageAdminValidationSchema) => {
    const phone = value.phoneNumber ? value.phoneNumber.replace('+', '') : '';
    value.phoneNumber = phone === '' ? null : phone;
    create(value);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex h-[calc(100vh-168px)] w-full flex-col gap-8">
          <div className="relative">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel isRequired>{t('Forms.firstName.label')}</FormLabel>
                  <FormControl>
                    <Input
                      hasError={!!fieldState.error}
                      className="w-full"
                      placeholder={t('Forms.firstName.placeholder')}
                      {...field}
                      type="text"
                    />
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="lastName"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel isRequired>{t('Forms.lastName.label')}</FormLabel>
                  <FormControl>
                    <Input
                      hasError={!!fieldState.error}
                      className="w-full"
                      {...field}
                      type="text"
                      placeholder={t('Forms.lastName.placeholder')}
                    />
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="email"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel isRequired>{t('Forms.email.label')}</FormLabel>
                  <FormControl>
                    <Input
                      hasError={!!fieldState.error}
                      className="w-full"
                      placeholder={t('Forms.email.placeholder')}
                      {...field}
                      type="text"
                    />
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.phoneNumber.label')}</FormLabel>
                  <FormControl>
                    <PhoneInput countries={PHONE_COUNTRIES} placeholder={t('Forms.phoneNumber.placeholder')} {...field} value={field.value ?? ''}  className="w-full" hasError={!!fieldState.error} />
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="role"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel isRequired>{t('Forms.accessLevel.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue
                            placeholder={
                              isLoadingRolesIds ? t('Common.loadingWait') : t('Forms.accessLevel.placeholder')
                            }
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {roles?.map((role) => (
                          <SelectItem key={role.id} value={role.name}>
                            {role.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
        </div>

        <footer className="flex h-20 flex-row items-center justify-between space-x-2">
          <Button onClick={onCancel} variant="ghost" type="button" size="lg" className="w-full">
            {t('Buttons.cancel')}
          </Button>
          <Button type="submit" size="lg" className="w-full" disabled={isPending}>
            {t('Buttons.add')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
