import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ParsedClientFromPDF } from '@/views/all-clients/interfaces/clients.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, ParsedClientFromPDF>, 'queryKey' | 'queryFn'>;

export const useLongPollingFileQuery = (fileId?: string, options?: Options) => {

  return useQuery({
    queryKey: ['long-polling', fileId],
    queryFn: async ({ signal }) => {
      const { data } = await axiosInterceptorInstance.get(`long-polling/${fileId}`, {
        // timeout: 180000,
        signal,
      });
      return data;
    },
    refetchOnMount: false,
    ...options,
  });
};
