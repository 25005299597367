import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ManageAdminValidationSchema } from '@/views/admin-users-list/manage-admin-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Request = {
  id: string;
  value: ManageAdminValidationSchema;
};

export type Options = Omit<
  UseMutationOptions<string, DefaultError, Request>,
  'mutationKey' | 'mutationFn'
>;

export const useUpdateAdminMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id, value }: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.put(`admins/${id}`, value);
    queryClient.invalidateQueries({ queryKey: ['admins', id] });
    queryClient.invalidateQueries({ queryKey: ['admins'] });
    queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
    return data;
  };

  return useMutation({
    mutationKey: ['admins'],
    mutationFn: _mutation,
    ...options,
  });
};
