import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { Dashboard } from '@/views/dashboard/dashboard.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Dashboard>, 'queryKey' | 'queryFn'>;

export const useDashboardsQuery = (options?: Options) => {
  const _query = async (): Promise<Dashboard> => {
    const { data } = await axiosInterceptorInstance.get('dashboard');
    return data;
  };

  return useQuery({
    queryKey: ['dashboard'],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
