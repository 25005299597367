'use client';
import { useTranslations } from 'next-intl';
import { AllTherapistsTable } from './table/all-therapists-table';

export function AllTherapistsView() {
  const t = useTranslations();

  return (
    <main className='pl-4 pr-6'>
      <h1 className='font-bold text-2xl py-4 mt-2'>{t('Pages.AllTherapists.title')}</h1>
      <AllTherapistsTable />
    </main>
  );
}
