'use client';

import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  SetupNewPasswordValidationSchema,
  setupNewPasswordValidationSchema,
} from './setup-new-password-validation-schema';
import { Button } from '@/components/ui';
import { Input, Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/form';
import { useTranslations } from 'next-intl';
import { toast, ToastTypeEnums } from '@/components/common';
import { useRouter } from 'next/navigation';
import { useSetupPassMutation } from '@/hooks/fetchers/mutations/auth/useSetupPassMutation';


export function SetupNewPasswordForm({ otp, userId, otpType }: { otp: string; userId: string; otpType: string }) {
  const t = useTranslations();
  const router = useRouter();
  const { mutate: setUpPassword, isPending } = useSetupPassMutation({
    onSuccess: () => {
      form.reset();
      toast({ title: t('Toasts.passwordSuccessfullyUpdated'), typeIcon: ToastTypeEnums.SUCCESS });
      router.push(`/auth/login`);
    }
  });

  const form = useForm<SetupNewPasswordValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(setupNewPasswordValidationSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const password = form.watch('password');

  useEffect(() => {
    if (password && form.getValues('confirmPassword').length > 0) {
      form.trigger('confirmPassword');
    }
  }, [password, form.trigger]);


  const onSubmit: SubmitHandler<SetupNewPasswordValidationSchema> = (data: SetupNewPasswordValidationSchema): void => {
    setUpPassword({
      otp,
      id: userId,
      password: data.password,
      otpType
    });
  };

  useEffect(() => {
    form.setFocus('password');
  }, []);

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="relative mb-10">
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>{t('Forms.createPassword.label')}</FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="password"
                    placeholder={t('Forms.password.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <div className="relative mb-2">
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>{t('Forms.confirmPassword.label')}</FormLabel>
                <FormControl>
                  <Input
                    hasError={!!fieldState.error}
                    className="w-full"
                    {...field}
                    type="password"
                    placeholder={t('Forms.confirmPassword.placeholder')}
                  />
                </FormControl>
                <FormMessage className="absolute" />
              </FormItem>
            )}
          />
        </div>

        <Button type="submit" size="xl" className="mt-8 w-full" disabled={isPending}>
          {isPending ? t('Common.loadingWait') : t('Buttons.submit')}
        </Button>
      </form>
    </Form>
  );
}
