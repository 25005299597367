'use client';
import { useTranslations } from 'next-intl';
import { MyDocumentsTable } from './table/my-documents-table';

export function MyDocumentsView() {
  const t = useTranslations();

  return (
    <main className='pl-4 pr-6'>
      <h1 className='font-bold text-2xl py-4 mt-2'>{t('Pages.MyDocuments.title')}</h1>
      <MyDocumentsTable />
    </main>
  );
}
