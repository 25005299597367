import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation } from '@tanstack/react-query';

export type Options = Omit<
  UseMutationOptions<string, DefaultError, string>,
  'mutationKey' | 'mutationFn'
>;

export const useForgotPassMutation = (options?: Options) => {
  const _mutation = async (email: string): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`auth/forgot-password`, {email});
    return data;
  };

  return useMutation({
    mutationKey: ['auth', 'forgot-password'],
    mutationFn: _mutation,
    ...options,
  });
};
