import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ProfileAccount } from '@/views/my-profile/interfaces/profile.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, ProfileAccount>, 'queryKey' | 'queryFn'>;

export const useMyProfileQuery = (options?: Options) => {
  const _query = async (): Promise<ProfileAccount> => {
    const { data } = await axiosInterceptorInstance.get('me', {
      params: {
        isMobile: false,
      },
    });
    return data;
  };

  return useQuery({
    queryKey: ['me'],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
