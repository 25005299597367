import { Schedule } from './../../../../views/schedule/schedule.interface';
import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

type Response = {
  schedules: Schedule[]
};

type ResponseApi = {
  schedules: {
    [key: string]: Schedule
  };
};

interface Params {
  search?: string;
  therapistIdList: string[];
  clientIdList: string[];
  rangeFrom: string;
  rangeTo: string;
}

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useSchedulesQuery = <T = Response>(params: Params, options?: Options<T>) => {
  const queryParams = {
    search: params.search,
    rangeFrom: params.rangeFrom,
    rangeTo: params.rangeTo,
    'therapistIdList': params.therapistIdList,
    'clientIdList': params.clientIdList,
  };
  const cleanedQueryParams = _.omitBy(queryParams, (value) => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    if (params.therapistIdList.length > 0 || params.clientIdList.length > 0) {
      const { data } = await axiosInterceptorInstance.get<ResponseApi>('shifts/schedule', {
        params: cleanedQueryParams,
      });
      return {
        schedules: Object.keys(data.schedules).map((key: string) => (data.schedules as any)[key] as Schedule)
      };
    } else {
      return {
        schedules: []
      }
    }
  };

  return useQuery({
    queryKey: ['shifts', 'schedule', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
