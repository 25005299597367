import { ColumnDef } from '@tanstack/react-table';
import dayjs from '@/lib/dayjsConfig';
import { TimesheetPayrollTherapist } from '../interfaces/timesheet-payroll-therapist.interface';
import { capitalizeFirstLetter, minutesToTime } from '@/lib/utils';

type Params = {
  t: any;
};

export const getDetailedCalculationColumns = ({ t }: Params): ColumnDef<TimesheetPayrollTherapist>[] => {
  const columns: ColumnDef<TimesheetPayrollTherapist>[] = [
    {
      id: 'Shift/Report',
      accessorKey: 'entryType',
      header: () => <span>{t('Tables.shiftOrReport')}</span>,
      accessorFn: (row) => (row.entryType ? capitalizeFirstLetter(row.entryType) : '---'),
      size: 250,
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'Type',
      accessorKey: 'workType',
      enablePinning: false,
      accessorFn: (row) => (row.workType ? capitalizeFirstLetter(row.workType) : '---'),
      header: () => <span>{t('Tables.type')}</span>,
      size: 140,
    },
    {
      id: 'Date',
      enablePinning: false,
      header: () => <span>{t('Tables.date')}</span>,
      accessorFn: (row) => (row.date ? dayjs(row.date).format('MM.DD.YYYY') : '---'),
      size: 140,
    },
    {
      id: 'Hours',
      accessorKey: 'duration',
      enablePinning: false,
      header: () => <span>{t('Tables.hours')}</span>,
      accessorFn: (row) => (row.duration ? minutesToTime(row.duration) : '---'),
      size: 140,
    },
    {
      id: 'Pay Rate',
      accessorKey: 'payRate',
      enablePinning: false,
      header: () => <span>{t('Tables.payRate')}</span>,
      accessorFn: (row) => (row.payRate ? `$${row.payRate}` : '---'),
      size: 140,
    },
    {
      id: 'Total Pay',
      accessorKey: 'totalPay',
      enablePinning: false,
      header: () => <span>{t('Tables.totalPay')}</span>,
      accessorFn: (row) => (row.totalPay ? `$${row.totalPay.toFixed(2)}` : '---'),
      size: 140,
    },
  ];

  return columns;
};
