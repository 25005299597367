import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { ManageServiceRoleValidationSchema } from '@/views/service-roles/create/manage-service-role-validation-schema';

export type Options = Omit<
  UseMutationOptions<string, DefaultError, ManageServiceRoleValidationSchema>,
  'mutationKey' | 'mutationFn'
>;

export const useCreateServiceRoleMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: ManageServiceRoleValidationSchema): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`service-roles`, value);
    return data;
  };

  return useMutation({
    mutationKey: ['service-roles'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['service-roles'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    ...options,
  });
};
