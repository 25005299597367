import { ReactNode, useEffect, useState } from 'react';
import { SendbirdChatWith } from '@sendbird/chat';
import {
  GroupChannelModule,
  GroupChannel,
} from '@sendbird/chat/groupChannel';
import { useTranslations } from 'next-intl';
interface ChatContainerProps {
  sb: SendbirdChatWith<GroupChannelModule[]>;
  channelRef: any;
  children: ReactNode;
  currentlyJoinedChannel: GroupChannel | null;
}

export function ChatContainer({ sb, channelRef, currentlyJoinedChannel, children }: ChatContainerProps) {
  const [title, setTitle] = useState<string>('');
  const t = useTranslations();

  useEffect(() => {
    if (currentlyJoinedChannel) {
      if (currentlyJoinedChannel.name === '' && currentlyJoinedChannel.memberCount === 2) {
        const interlocutor = currentlyJoinedChannel.members.find((member: any) => member.userId !== sb.currentUser?.userId);
        if (interlocutor) {
          setTitle(interlocutor.nickname);
        }
      } else {
        setTitle(currentlyJoinedChannel.name);
      }
    }
  }, [currentlyJoinedChannel])

  if (currentlyJoinedChannel) {
    return (
      <main className="flex h-[calc(100vh-64px)] w-full flex-col overflow-auto" ref={channelRef}>
        <header className="flex h-14 flex-row items-center border-b border-gray-300 bg-white px-3 py-2">
          <div className="flex flex-col">
            <h1 className="font-medium">{title}</h1>
          </div>
        </header>
        <div>{children}</div>
      </main>
    );
  }
  return (
    <div className="flex h-[calc(100vh-64px)] w-full flex-col items-center justify-center">
      <h1 className='bg-blue-50 py-2 px-6 rounded-md'>{t('Pages.Chats.selectOrStartNewConversation')}</h1>
    </div>
  );
}
