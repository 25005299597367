import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ProfileChangePasswordForm } from '@/views/my-profile/interfaces/profile.interface';
import { DefaultError, UseMutationOptions, useMutation } from '@tanstack/react-query';

export type Options = Omit<
  UseMutationOptions<string, DefaultError, ProfileChangePasswordForm>,
  'mutationKey' | 'mutationFn'
>;

export const useUpdateMyPasswordMutation = (options?: Options) => {
  const _mutation = async (value: ProfileChangePasswordForm): Promise<string> => {
    const { data } = await axiosInterceptorInstance.patch(`me/change-password`, value);
    return data;
  };

  return useMutation<string, Error, any>({
    mutationKey: ['me', 'change-password'],
    mutationFn: _mutation,
    ...options,
  });
};
