'use client';
import { useTranslations } from 'next-intl';
import { toast, BackButton, ToastTypeEnums } from '@/components/common';
import { Button } from '@/components/ui';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { useForgotPassMutation } from '@/hooks/fetchers/mutations/auth/useForgotPassMutation';

export function ForgotPasswordConfirmView({ email }: { email: string }) {
  const t = useTranslations();
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [seconds]);

  const { mutate: onResend, isPending } = useForgotPassMutation({
    onSuccess: () => {
      setSeconds(60);
      toast({ title: t('Toasts.resentEmailSuccessfully'), typeIcon: ToastTypeEnums.SUCCESS });
    }
  })

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-0 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
          {t('Pages.Auth.checkYourEmail')}
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
        <p className="text-center text-base font-extralight leading-8 text-gray-900">
          {t('Pages.Auth.checkYourEmailDescription', { emailAddress: email })}
        </p>

        <Button
          type="button"
          size="xl"
          className="mt-8 w-full"
          onClick={() => onResend(email)}
          disabled={seconds !== 0 || isPending}
        >
          {seconds > 0 ? t('Buttons.pleaseWaitResendSec', { sec: seconds }) : t('Buttons.resendLink')}
        </Button>
        <BackButton />
      </div>
    </div>
  );
}
