'use client';
import { useTranslations } from 'next-intl';
import { AllServicesTable } from './table/all-services-table';

export function AllServicesView() {
  const t = useTranslations();

  return (
    <main className='pl-4 pr-6'>
      <h1 className="mt-2 py-4 text-2xl font-bold">{t('Pages.AllServices.title')}</h1>

      <AllServicesTable />
    </main>
  );
}
